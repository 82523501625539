import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQuery } from './baseQuery'
import { IPersonsByRoomResponse, IPersonUpdatePayload } from '../../types/persons'
import { IGenericResponse } from '../../types/generic'

export const personsApi = createApi({
  reducerPath: 'personsApi',
  baseQuery,
  endpoints: (builder) => ({
    getPersonsByRoom: builder.query<IPersonsByRoomResponse[], number>({
      query: (stateroom) => ({
        url: `/persons/${stateroom}/guests`,
        method: 'GET',
      }),
    }),
    update: builder.mutation<IGenericResponse, IPersonUpdatePayload>({
      query: ({ personId, ...rest }) => ({
        url: `/persons/${personId}`,
        method: 'PUT',
        body: rest,
      }),
    }),
  }),
})

export const { useGetPersonsByRoomQuery, useUpdateMutation, useLazyGetPersonsByRoomQuery } =
  personsApi
