import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import GetAppOutlinedIcon from '@mui/icons-material/GetAppOutlined'
import { Button, Grid, TextField } from '@mui/material'
import { useParams } from 'react-router-dom'
import { LoadingButton } from '@mui/lab'
import { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useReport } from './hook'
import {} from './types'
import SettingsLayout from '../../../components/SettingsLayout'
import GridSpinner from '../../../components/GridSpinner'
import { EFeedbackQuestions, IFeedback, IFeedbackPayload } from '../Voyages/types'

const VoyageReport = () => {
  const {
    isLoading,
    data,
    onDownloadExcel,
    isFetchingDownload,
    refetchGetReport,
    cruiseDetail,
    isLoadingCruiseDetail,
    updateFeedback,
    isLoadingUpdateFeedback,
  } = useReport()
  const { id } = useParams()
  useEffect(() => {
    refetchGetReport()
  }, [id])

  const [isEditing, setIsEditing] = useState<boolean>(false)
  const [isCreating, setIsCreating] = useState<boolean>(false)
  const [formData, setFormData] = useState<IFeedback>({
    successful_initiatives: [],
    challenges_identified: [],
    guest_reviews: [],
  })

  useEffect(() => {
    if (cruiseDetail) {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const { successful_initiatives, challenges_identified, guest_reviews } =
        cruiseDetail!.feedback!
      if (
        successful_initiatives.length === 0 &&
        challenges_identified.length === 0 &&
        guest_reviews.length === 0
      ) {
        setIsCreating(true)
      }
      setFormData({
        successful_initiatives: successful_initiatives || [],
        challenges_identified: challenges_identified || [],
        guest_reviews: guest_reviews || [],
      })
    }
  }, [cruiseDetail])

  const { control, handleSubmit, reset } = useForm<IFeedback>({
    defaultValues: {
      successful_initiatives: formData.successful_initiatives,
      challenges_identified: formData.challenges_identified,
      guest_reviews: formData.guest_reviews,
    },
  })

  useEffect(() => {
    reset({
      successful_initiatives: formData.successful_initiatives,
      challenges_identified: formData.challenges_identified,
      guest_reviews: formData.guest_reviews,
    })
  }, [formData, reset])

  const onHandleSubmit = async (values: IFeedback) => {
    const successfulInitiatives = values[EFeedbackQuestions.SUCCESSFUL_INITIATIVES].map((item) => ({
      id: item.id || undefined,
      cruise_id: Number(id!),
      question: item.question || EFeedbackQuestions.SUCCESSFUL_INITIATIVES,
      answer: item.answer || '',
    }))
    const challengesIdentified = values[EFeedbackQuestions.CHALLENGES_IDENTIFIED].map((item) => ({
      id: item.id || undefined,
      cruise_id: Number(id!),
      question: item.question || EFeedbackQuestions.CHALLENGES_IDENTIFIED,
      answer: item.answer || '',
    }))
    const guestReviews = values[EFeedbackQuestions.GUEST_REVIEWS].map((item) => ({
      id: item.id || undefined,
      cruise_id: Number(id!),
      question: item.question || EFeedbackQuestions.GUEST_REVIEWS,
      answer: item.answer || '',
    }))
    const feedbackPayload: IFeedbackPayload = {
      cruiseId: Number(id!),
      feedback: {
        successful_initiatives: isCreating
          ? successfulInitiatives.filter((item) => item.answer !== '')
          : successfulInitiatives,
        challenges_identified: isCreating
          ? challengesIdentified.filter((item) => item.answer !== '')
          : challengesIdentified,
        guest_reviews: isCreating
          ? guestReviews.filter((item) => item.answer !== '')
          : guestReviews,
      },
    }
    await updateFeedback(feedbackPayload)
    setIsEditing(false)
    setIsCreating(false)
  }

  const handleEdit = () => {
    setIsEditing(true)
  }

  return (
    <SettingsLayout>
      <Stack direction="row" justifyContent="space-between" sx={{ pb: 2 }}>
        {!isLoading && !!data?.length && (
          <Box>
            <LoadingButton
              loading={isFetchingDownload}
              variant="contained"
              color="success"
              startIcon={<GetAppOutlinedIcon />}
              onClick={() => onDownloadExcel(Number(id))}>
              Export to Excel
            </LoadingButton>
          </Box>
        )}
        <Typography variant="h4" color="primary" sx={{ display: { xs: 'none', md: 'flex' } }}>
          Voyage Report
        </Typography>
      </Stack>

      {isLoading || isLoadingCruiseDetail ? (
        <GridSpinner />
      ) : (
        <Paper
          sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
          elevation={3}>
          <Box
            component="form"
            onSubmit={handleSubmit(onHandleSubmit)}
            noValidate
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              width: '90%',
            }}>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Typography marginTop={2}>
                Kindly share your feedback on the voyage, including key actions, challenges, and
                guest feedback. This feedback will be included in the report.
              </Typography>
            </Stack>
            {(isEditing || isCreating) && (
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Typography marginTop={2} style={{ fontWeight: 'bold' }}>
                    What are the top actions or initiatives that went well?
                  </Typography>
                  {[...Array(5)].map((_, index) => (
                    <Grid item xs={12} key={index}>
                      <Controller
                        control={control}
                        name={`successful_initiatives.${index}.answer`}
                        render={({ field: { ref, ...field } }) => (
                          <TextField
                            {...field}
                            inputRef={ref}
                            margin="normal"
                            fullWidth
                            multiline
                            id="successful_initiatives"
                            type="text"
                            rows={1}
                            disabled={isLoadingUpdateFeedback}
                          />
                        )}
                      />
                    </Grid>
                  ))}
                </Grid>
                <Grid item xs={12}>
                  <Typography marginTop={2} style={{ fontWeight: 'bold' }}>
                    What (if anything) was challenging in this cruise?
                  </Typography>
                  {[...Array(5)].map((_, index) => (
                    <Grid item xs={12} key={index}>
                      <Controller
                        control={control}
                        name={`challenges_identified.${index}.answer`}
                        render={({ field: { ref, ...field } }) => (
                          <TextField
                            {...field}
                            inputRef={ref}
                            margin="normal"
                            fullWidth
                            multiline
                            id="challenges_identified"
                            type="text"
                            rows={1}
                            disabled={isLoadingUpdateFeedback}
                          />
                        )}
                      />
                    </Grid>
                  ))}
                </Grid>
                <Grid item xs={12}>
                  <Typography marginTop={2} style={{ fontWeight: 'bold' }}>
                    What are guests saying?
                  </Typography>
                  {[...Array(5)].map((_, index) => (
                    <Grid item xs={12} key={index}>
                      <Controller
                        control={control}
                        name={`guest_reviews.${index}.answer`}
                        render={({ field: { ref, ...field } }) => (
                          <TextField
                            {...field}
                            inputRef={ref}
                            margin="normal"
                            fullWidth
                            multiline
                            id="guest_reviews"
                            type="text"
                            rows={1}
                            disabled={isLoadingUpdateFeedback}
                          />
                        )}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            )}

            {cruiseDetail && !isCreating && !isEditing && (
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Typography marginTop={2} style={{ fontWeight: 'bold' }}>
                    What are the top actions or initiatives that went well?
                  </Typography>
                  {cruiseDetail.feedback?.successful_initiatives.map((item, index) => (
                    <Grid item xs={12} key={index}>
                      <Typography>{`- ${item.answer}`}</Typography>
                    </Grid>
                  ))}
                </Grid>
                <Grid item xs={12}>
                  <Typography marginTop={2} style={{ fontWeight: 'bold' }}>
                    What (if anything) was challenging in this cruise?
                  </Typography>
                  {cruiseDetail.feedback?.challenges_identified.map((item, index) => (
                    <Grid item xs={12} key={index}>
                      <Typography>{`- ${item.answer}`}</Typography>
                    </Grid>
                  ))}
                </Grid>
                <Grid item xs={12}>
                  <Typography marginTop={2} style={{ fontWeight: 'bold' }}>
                    What are guests saying?
                  </Typography>
                  {cruiseDetail.feedback?.guest_reviews.map((item, index) => (
                    <Grid item xs={12} key={index}>
                      <Typography>{`- ${item.answer}`}</Typography>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            )}

            {!isCreating && !isEditing && (
              <Button
                variant="contained"
                onClick={handleEdit}
                color="secondary"
                sx={{
                  alignSelf: 'flex-end',
                  margin: '25px 0',
                }}>
                Edit
              </Button>
            )}

            {(isEditing || isCreating) && (
              <LoadingButton
                loading={isLoadingUpdateFeedback}
                type="submit"
                variant="contained"
                sx={{ alignSelf: 'flex-end', margin: '25px 0' }}>
                Save
              </LoadingButton>
            )}
          </Box>
        </Paper>
      )}
    </SettingsLayout>
  )
}

export default VoyageReport
