import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  TextField,
} from '@mui/material'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import moment, { Moment } from 'moment'
import { useEffect, useState } from 'react'
import { validationSchema } from './validations'
import { IInitiativeFormProps } from './types'
import { marketingInitiativesTypes } from '../../utils/constants'
import { IInitiative } from '../VoyageForm/types'

const InitiativeForm = (props: IInitiativeFormProps) => {
  const { initiative, vessels, products, embarkDate, debarkDate, isOpen, onAccept, onReject } =
    props
  const [selectDate, setSelectDate] = useState<Moment | null>(
    initiative?.date ? moment(initiative.date) : null,
  )

  const {
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors },
  } = useForm<IInitiative>({
    resolver: yupResolver(validationSchema),
    defaultValues: { ...initiative },
  })

  // Update product name when the product ID changes
  const selectedProductId = watch('product_id')
  useEffect(() => {
    const selectedProduct = products.find((product) => product.id === selectedProductId)
    if (selectedProduct) {
      setValue('product', selectedProduct.name)
    }
  }, [selectedProductId, setValue])

  // Update product name when the ship ID changes
  const selectedShipId = watch('ship_id')
  useEffect(() => {
    const selectedShip = vessels.find((ship) => ship.id === selectedShipId)
    if (selectedShip) {
      setValue('ship_name', selectedShip.name)
    }
  }, [selectedShipId, setValue])

  const title = initiative?.initiative_id ? 'Edit Initiative' : 'New Initiative'

  return (
    <Dialog open={isOpen} onClose={onReject} fullWidth maxWidth="sm">
      <Box component="form" onSubmit={handleSubmit(onAccept)} noValidate>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} sx={{ marginBottom: 2 }}>
            <Grid item xs={12}>
              <Controller
                control={control}
                name="initiative_type"
                render={({ field: { ref, ...field } }) => (
                  <TextField
                    {...field}
                    inputRef={ref}
                    margin="normal"
                    fullWidth
                    select
                    id="initiative_type"
                    label="Initiative"
                    type="text"
                    defaultValue=""
                    error={!!errors.initiative_type}
                    helperText={errors?.initiative_type?.message as string}>
                    {marketingInitiativesTypes.map((type) => (
                      <MenuItem key={`initiative-type-key-${type}`} value={type}>
                        {type}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={control}
                name="product_id"
                render={({ field: { ref, ...field } }) => (
                  <TextField
                    {...field}
                    inputRef={ref}
                    margin="normal"
                    fullWidth
                    select
                    id="product_id"
                    label="Product"
                    type="text"
                    error={!!errors.product_id}
                    helperText={errors?.product_id?.message as string}>
                    {products.map((product) => (
                      <MenuItem key={`product-key-${product.id}`} value={product.id}>
                        {product.name}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                control={control}
                name="ship_id"
                render={({ field: { ref, ...field } }) => (
                  <TextField
                    {...field}
                    inputRef={ref}
                    fullWidth
                    margin="normal"
                    select
                    id="ship_id"
                    label="Ship"
                    type="text"
                    defaultValue=""
                    error={!!errors.ship_id}
                    helperText={errors?.ship_id?.message as string}>
                    {vessels.map((vessel) => (
                      <MenuItem key={`vessel-key-${vessel.id}`} value={vessel.id}>
                        {vessel.name}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                control={control}
                name="date"
                render={({ field: { onChange, value, ...field } }) => (
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker
                      label="Date"
                      minDate={embarkDate}
                      maxDate={debarkDate}
                      value={selectDate}
                      onChange={(newValue) => {
                        setSelectDate(moment(newValue))
                        onChange(newValue)
                      }}
                      renderInput={(params: any) => (
                        <TextField {...params} fullWidth error={!!errors.date} sx={{ mt: 2 }} />
                      )}
                      {...field}
                    />
                  </LocalizationProvider>
                )}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={onReject}>Cancel</Button>
          <Button type="submit">Save</Button>
        </DialogActions>
      </Box>
    </Dialog>
  )
}

export default InitiativeForm
