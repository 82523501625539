import {
  MaterialReactTable,
  MRT_ColumnDef,
  MRT_Row,
  useMaterialReactTable,
} from 'material-react-table'
import React, { ReactNode, useEffect, useMemo, useState } from 'react'
import moment from 'moment-timezone'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { IAppointmentsGridProps } from './types'
import DateTimeColumn from './DateTimeColumn'
import LastActionColumn from './LastActionColumn'
import ActionsColumn from './ActionsColumn'
import PreviousColumn from './PreviousColumn'
import { headTableStyle } from '../../styles/generic-styles'
import {
  EAppointmentDBType,
  EBookingStatus,
  EOtherResult,
  EQuoteStatus,
  IAppointment,
} from '../../types/appointments'
import { theme } from '../../styles/mui-theme'

const AppointmentsGrid: React.FC<IAppointmentsGridProps> = ({
  appointments,
  isLoading,
  isInLineGrid,
  enableRowSelection,
  enableSorting = false,
  onSelectAppointment,
  onDragEnd,
  onSelectNoShowAppointment,
  onSelectMultipleAppointments,
  onSelectClearPendingTasks,
}) => {
  const checkCallbackDate = (type: string, callback: { date: string; time: string }): string => {
    if (type === EAppointmentDBType.CALLBACK) {
      const dateToEval = moment.utc(callback.date)
      const today = moment.utc().startOf('day')
      const tomorrow = moment.utc().add(1, 'days').startOf('day')
      const time = `at ${callback.time}`

      if (dateToEval.isSame(today, 'd')) return `Today ${time}`
      if (dateToEval.isSame(tomorrow, 'd')) return `Tomorrow ${time}`

      return `${moment.utc(dateToEval).format('MMM Do')} at ${callback.time}`
    }
    return ''
  }

  const checkResults = (appointment: IAppointment): ReactNode | string => {
    const results = []
    const { quotes, bookings, others } = appointment

    // OWN QUOTES
    const ownQuotesDone =
      quotes?.filter(
        (quote) => quote.quote_status === EQuoteStatus.DONE && quote.isFromThisAppointment,
      ) ?? []

    if (ownQuotesDone.length > 0) results.push(<span>{`Quotes (${ownQuotesDone.length})`}</span>)

    const ownBookingsDraft =
      bookings?.filter(
        (booking) =>
          booking.booking_status_future === EBookingStatus.DRAFT && booking.isFromThisAppointment,
      ) ?? []

    const ownBookingOffered =
      bookings?.filter(
        (booking) =>
          booking.booking_status_future === EBookingStatus.OFFERED && booking.isFromThisAppointment,
      ) ?? []

    const ownBookingsConfirmed =
      bookings?.filter(
        (booking) =>
          booking.booking_status_future === EBookingStatus.CONFIRMED &&
          booking.isFromThisAppointment,
      ) ?? []

    const ownBookingsCanceled =
      bookings?.filter(
        (booking) =>
          booking.booking_status_future === EBookingStatus.CANCELLED &&
          booking.isFromThisAppointment,
      ) ?? []

    if (ownBookingsDraft.length > 0)
      results.push(
        <span style={{ display: 'flex' }}>{`Bookings Draft (${ownBookingsDraft.length}`}</span>,
      )
    if (ownBookingOffered.length > 0)
      results.push(
        <span style={{ display: 'flex' }}>{`Bookings Offered (${ownBookingOffered.length})`}</span>,
      )
    if (ownBookingsConfirmed.length > 0)
      results.push(
        <span style={{ display: 'flex' }}>
          {`Bookings Confirmed (${ownBookingsConfirmed.length})`}
        </span>,
      )
    if (ownBookingsCanceled.length > 0)
      results.push(
        <span style={{ display: 'flex' }}>
          {`Bookings Canceled (${ownBookingsCanceled.length})`}
        </span>,
      )

    // OWN OTHERS
    const ownOthersNoShow =
      others?.filter(
        (other) => other.result === EOtherResult.NOSHOW && other.isFromThisAppointment,
      ) ?? []

    const ownOthersExistingBooking =
      others?.filter(
        (other) => other.result === EOtherResult.EXISTING_BOOKING && other.isFromThisAppointment,
      ) ?? []

    const ownOthersNotRelated =
      others?.filter(
        (other) => other.result === EOtherResult.NOTRELATED && other.isFromThisAppointment,
      ) ?? []

    if (ownOthersNoShow.length > 0)
      results.push(<span>{`No Show (${ownOthersNoShow.length})`}</span>)
    if (ownOthersExistingBooking.length > 0)
      results.push(<span>{`Existing Booking (${ownOthersExistingBooking.length})`}</span>)
    if (ownOthersNotRelated.length > 0)
      results.push(<span>{`Not Related (${ownOthersNotRelated.length})`}</span>)

    // QUOTES (OTHER APPOINTMENTS)
    const quotesDone =
      quotes?.filter(
        (quote) => quote.quote_status === EQuoteStatus.DONE && !quote.isFromThisAppointment,
      ) ?? []

    if (quotesDone.length > 0)
      results.push(
        <Box
          component="span"
          sx={{ color: 'rgba(0,0,0,.5)' }}>{`Quotes (${quotesDone.length})`}</Box>,
      )

    // BOOKINGS (OTHER APPOINTMENTS)
    const bookingsDraft =
      bookings?.filter(
        (booking) =>
          booking.booking_status_future === EBookingStatus.DRAFT && !booking.isFromThisAppointment,
      ) ?? []

    const bookingOffered =
      bookings?.filter(
        (booking) =>
          booking.booking_status_future === EBookingStatus.OFFERED &&
          !booking.isFromThisAppointment,
      ) ?? []

    const bookingsConfirmed =
      bookings?.filter(
        (booking) =>
          booking.booking_status_future === EBookingStatus.CONFIRMED &&
          !booking.isFromThisAppointment,
      ) ?? []

    const bookingsCanceled =
      bookings?.filter(
        (booking) =>
          booking.booking_status_future === EBookingStatus.CANCELLED &&
          !booking.isFromThisAppointment,
      ) ?? []

    if (bookingsDraft.length > 0)
      results.push(
        <Box
          component="span"
          sx={{ color: 'rgba(0,0,0,.5)' }}>{`Bookings Draft (${bookingsDraft.length}`}</Box>,
      )
    if (bookingOffered.length > 0)
      results.push(
        <Box
          component="span"
          sx={{ color: 'rgba(0,0,0,.5)' }}>{`Bookings Offered (${bookingOffered.length})`}</Box>,
      )
    if (bookingsConfirmed.length > 0)
      results.push(
        <Box
          component="span"
          sx={{
            color: 'rgba(0,0,0,.5)',
          }}>{`Bookings Confirmed (${bookingsConfirmed.length})`}</Box>,
      )
    if (bookingsCanceled.length > 0)
      results.push(
        <Box
          component="span"
          sx={{ color: 'rgba(0,0,0,.5)' }}>{`Bookings Canceled (${bookingsCanceled.length})`}</Box>,
      )

    // OTHERS (OTHER APPOINTMENTS)
    const othersNoShow =
      others?.filter(
        (other) => other.result === EOtherResult.NOSHOW && !other.isFromThisAppointment,
      ) ?? []

    const othersExistingBooking =
      others?.filter(
        (other) => other.result === EOtherResult.EXISTING_BOOKING && !other.isFromThisAppointment,
      ) ?? []

    const othersNotRelated =
      others?.filter(
        (other) => other.result === EOtherResult.NOTRELATED && !other.isFromThisAppointment,
      ) ?? []

    if (othersNoShow.length > 0)
      results.push(
        <Box
          component="span"
          sx={{ color: 'rgba(0,0,0,.5)' }}>{`No Show (${othersNoShow.length})`}</Box>,
      )
    if (othersExistingBooking.length > 0)
      results.push(
        <Box
          component="span"
          sx={{
            color: 'rgba(0,0,0,.5)',
          }}>{`Existing Booking (${othersExistingBooking.length})`}</Box>,
      )
    if (othersNotRelated.length > 0)
      results.push(
        <Box
          component="span"
          sx={{ color: 'rgba(0,0,0,.5)' }}>{`Not Related(${othersNotRelated.length})`}</Box>,
      )

    if (results.length === 0) return 'N/A'

    return (
      <Stack direction="column" justifyContent="center" alignItems="flex-start">
        {results.map((item, index) => (
          <React.Fragment key={`results-${appointment.appointment_id}-${index}`}>
            {item}
          </React.Fragment>
        ))}
      </Stack>
    )
  }

  const dateColumn = (appointment: IAppointment) => <DateTimeColumn appointment={appointment} />
  const lastActionColumn = (appointment: IAppointment) => (
    <LastActionColumn appointment={appointment} />
  )
  const actionsColumn = (appointment: IAppointment) => (
    <ActionsColumn appointment={appointment} openDialog={onSelectNoShowAppointment!} />
  )
  const previousColumn = (appointment: IAppointment) => (
    <PreviousColumn appointment={appointment} openDialog={onSelectClearPendingTasks!} />
  )

  const [selectedRows, setSelectedRows] = useState({})

  const columns = isInLineGrid
    ? useMemo<MRT_ColumnDef<IAppointment>[]>(
        () => [
          {
            accessorKey: 'person.room',
            header: 'ROOM',
            // eslint-disable-next-line react/no-unstable-nested-components
            Cell: ({ row }) => {
              const { person } = row.original
              const isUnknown = person?.room === 0
              const label = (
                <Typography
                  sx={{ color: isUnknown ? theme.palette.error.main : theme.palette.custom.main }}>
                  {isUnknown ? 'UNK' : person?.room}
                </Typography>
              )
              return label
            },
            muiTableBodyCellProps: ({ row }) => ({
              onClick: () => {
                if (!enableRowSelection) onSelectAppointment(row.original)
              },
            }),
            size: 80,
          },
          {
            accessorKey: 'person.first_name',
            header: 'GUEST',
            Cell: ({ row }) => {
              const { person } = row.original
              return `${person?.first_name} ${person?.last_name}`
            },
            muiTableBodyCellProps: ({ row }) => ({
              onClick: () => {
                if (!enableRowSelection) onSelectAppointment(row.original)
              },
            }),
          },
          {
            accessorKey: 'queued_on',
            header: 'APPOINTMENT DATE/TIME',
            Cell: ({ row }) => dateColumn(row.original),
            muiTableBodyCellProps: ({ row }) => ({
              onClick: () => {
                if (!enableRowSelection) onSelectAppointment(row.original)
              },
            }),
          },
          {
            header: 'LAST ACTION',
            Cell: ({ row }) => lastActionColumn(row.original),
            muiTableBodyCellProps: ({ row }) => ({
              onClick: () => {
                if (!enableRowSelection) onSelectAppointment(row.original)
              },
            }),
          },
          {
            header: 'TYPE',
            Cell: ({ row }) => {
              let type: ReactNode
              if (row.original.type === EAppointmentDBType.QUEUED) {
                type = (
                  <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                    In Line
                  </Typography>
                )
              } else if (row.original.type === EAppointmentDBType.CALLBACK) {
                type = (
                  <Typography variant="body2">
                    <Box component="span" sx={{ fontWeight: 'bold' }}>
                      {row.original?.inPersonCallback ? 'Appt' : 'Call'}{' '}
                    </Box>
                    {checkCallbackDate(row.original.type, row.original.callback!)}
                  </Typography>
                )
              } else if (row.original.type === EAppointmentDBType.CRUISELATER) {
                type = (
                  <Typography
                    variant="body2"
                    sx={{ fontWeight: 'bold', color: theme.palette.custom.tangerine }}>
                    App Booking
                  </Typography>
                )
              }
              return type
            },
            muiTableBodyCellProps: ({ row }) => ({
              onClick: () => {
                if (!enableRowSelection) onSelectAppointment(row.original)
              },
            }),
            size: 120,
          },
          {
            header: 'PREVIOUS',
            Cell: ({ row }) => previousColumn(row.original),
            muiTableBodyCellProps: ({ row }) => ({
              onClick: () => {
                if (!enableRowSelection) onSelectAppointment(row.original)
              },
            }),
            size: 100,
          },
          {
            header: ' ',
            Cell: ({ row }) => actionsColumn(row.original),
            size: 10,
          },
        ],
        [],
      )
    : useMemo<MRT_ColumnDef<IAppointment>[]>(
        () => [
          {
            accessorKey: 'person.room',
            header: 'ROOM',
            muiTableBodyCellProps: ({ row }) => ({
              onClick: () => {
                if (!enableRowSelection) onSelectAppointment(row.original)
              },
            }),
            size: 80,
          },
          {
            accessorKey: 'person.first_name',
            header: 'GUEST',
            Cell: ({ row }) => {
              const { person } = row.original
              return `${person?.first_name} ${person?.last_name}`
            },
            muiTableBodyCellProps: ({ row }) => ({
              onClick: () => {
                if (!enableRowSelection) onSelectAppointment(row.original)
              },
            }),
            size: 120,
          },
          {
            accessorKey: 'queued_on',
            header: 'APPOINTMENT DATE/TIME',
            Cell: ({ row }) => dateColumn(row.original),
            muiTableBodyCellProps: ({ row }) => ({
              onClick: () => {
                if (!enableRowSelection) onSelectAppointment(row.original)
              },
            }),
          },
          {
            accessorKey: 'agent.first_name',
            header: 'AGENT',
            Cell: ({ row }) =>
              row.original?.agent?.agent_id
                ? `${row.original.agent.first_name} ${row.original.agent.last_name}`
                : 'N/A',
            muiTableBodyCellProps: ({ row }) => ({
              onClick: () => {
                if (!enableRowSelection) onSelectAppointment(row.original)
              },
            }),
            size: 120,
          },
          {
            accessorKey: 'recorded_on',
            header: 'LAST ACTION',
            Cell: ({ row }) => lastActionColumn(row.original),
            muiTableBodyCellProps: ({ row }) => ({
              onClick: () => {
                if (!enableRowSelection) onSelectAppointment(row.original)
              },
            }),
          },
          {
            header: 'RESULTS',
            Cell: ({ row }) => checkResults(row.original),
            muiTableBodyCellProps: ({ row }) => ({
              onClick: () => {
                if (!enableRowSelection) onSelectAppointment(row.original)
              },
            }),
            size: 180,
          },
          {
            header: 'APPOINTMENT FLAGS',
            Cell: ({ row }) => previousColumn(row.original),
            muiTableBodyCellProps: ({ row }) => ({
              onClick: () => {
                if (!enableRowSelection) onSelectAppointment(row.original)
              },
            }),
          },
        ],
        [],
      )

  const table = useMaterialReactTable({
    columns,
    data: appointments,
    enableColumnActions: false,
    enableColumnFilters: false,
    enablePagination: false,
    enableSorting,
    enableBottomToolbar: false,
    enableTopToolbar: false,
    enableRowSelection,
    enableSelectAll: false,
    enableRowOrdering: isInLineGrid,
    muiTableHeadCellProps: {
      sx: headTableStyle,
    },
    state: {
      isLoading,
      rowSelection: selectedRows,
    },
    localization: {
      noRecordsToDisplay: 'No appointments in this status.',
      move: 'MOVE',
    },
    muiTableBodyProps: {
      sx: {
        '& .MuiTypography-root': {
          maxWidth: 'none',
        },
        '& .MuiTableRow-root': {
          cursor: 'pointer',
        },
        '& .MuiTableCell-root': {
          paddingY: '10px',
        },
      },
    },
    muiRowDragHandleProps: () => ({
      onDragEnd: () => {
        const { draggingRow, hoveredRow } = table.getState()
        if (hoveredRow && draggingRow) {
          const appt = [...appointments]
          appt.splice(
            (hoveredRow as MRT_Row<IAppointment>).index,
            0,
            appt.splice(draggingRow.index, 1)[0],
          )
          const positionsChanged =
            appointments.indexOf(draggingRow.original) - appt.indexOf(draggingRow.original)

          if (onDragEnd && !appt.every((element, index) => element === appointments[index])) {
            onDragEnd(draggingRow.original, positionsChanged)
          }
        }
      },
    }),
    muiTableBodyRowProps: ({ row }) => ({
      onClick: row.getToggleSelectedHandler(),
    }),
    onRowSelectionChange: setSelectedRows,
  })

  useEffect(() => {
    const selectedAppointments: IAppointment[] = table
      ? table.getSelectedRowModel().flatRows.map((row) => row.original)
      : []
    if (onSelectMultipleAppointments) onSelectMultipleAppointments(selectedAppointments)
  }, [selectedRows])

  return <MaterialReactTable table={table} />
}

export default AppointmentsGrid
