import { useState } from 'react'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import Button from '@mui/material/Button'
import Alert from '@mui/material/Alert'
import Stack from '@mui/material/Stack'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Tooltip from '@mui/material/Tooltip'
import Fab from '@mui/material/Fab'
import DeleteIcon from '@mui/icons-material/Delete'
import { Box, Link, Paper } from '@mui/material'
import moment from 'moment'
import { LoadingButton } from '@mui/lab'
import { useForm } from 'react-hook-form'
import { IGuestExtraDataFormProps } from './types'
import { CellStyles } from './styles'
import ConfirmDialog from '../ConfirmDialog'
import { headTableStyle } from '../../styles/generic-styles'
import UploadFileForm from '../UploadFileForm'
import { IUploadFileForm } from '../UploadFileForm/types'

const acceptedFiles = [
  // eslint-disable-next-line
  '.xlsx',
  // eslint-disable-next-line
  '.xls',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.ms-excel',
]

const UploadGuestExtraData: React.FC<IGuestExtraDataFormProps> = ({
  files,
  isLoading,
  onRemoveFile,
  onUploadFile,
  onSubmit,
  goBack,
}) => {
  const { handleSubmit } = useForm<IUploadFileForm>()

  const [isConfirmOpen, setIsConfirmOpen] = useState(false)
  const [isUploadFormOpen, setIsUploadFormOpen] = useState(false)
  const [recordToDelete, setRecordToDelete] = useState('')

  const onAccept = async (values: IUploadFileForm): Promise<void> => {
    setIsUploadFormOpen(false)
    onUploadFile(values)
  }

  const onSelectToRemove = (fileName: string) => {
    setRecordToDelete(fileName)
    setIsConfirmOpen(true)
  }

  const onAcceptRemove = () => {
    onRemoveFile(recordToDelete)
    setIsConfirmOpen(false)
  }

  return (
    <Box component="form" noValidate>
      <Stack direction="row" justifyContent="space-between" sx={{ pb: 2 }}>
        <Stack direction="row">
          <Button
            variant="contained"
            color="success"
            startIcon={<AddCircleOutlineIcon />}
            onClick={() => setIsUploadFormOpen(true)}>
            UPLOAD
          </Button>
        </Stack>
      </Stack>
      <Paper sx={{ marginBottom: 2 }}>
        <Alert severity="info">
          {/* eslint-disable-next-line */}
          <Link href="/guest-template.xlsx" download>
            Download this template
          </Link>{' '}
          and fill in to upload new guest records.
        </Alert>
      </Paper>
      {files && files.length > 0 && (
        <TableContainer
          sx={{
            maxHeight: 'calc(100vh - 340px)',
            backgroundColor: 'primary.contrastText',
            marginBottom: 2,
          }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell sx={headTableStyle}>FILE NAME</TableCell>
                <TableCell
                  sx={{
                    ...headTableStyle,
                    minWidth: 300,
                    width: 300,
                  }}>
                  DATE
                </TableCell>
                <TableCell
                  sx={{
                    ...headTableStyle,
                    minWidth: 100,
                    width: 100,
                  }}
                  align="center">
                  GUESTS
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    ...headTableStyle,
                    minWidth: 60,
                    width: 60,
                  }}
                />
              </TableRow>
            </TableHead>
            <TableBody>
              {files.map((item, idx) => (
                <TableRow key={`table-row-extra-info-${idx}`}>
                  <TableCell>
                    <CellStyles disable={!!item.deleted}>{item.name}</CellStyles>
                  </TableCell>
                  <TableCell>
                    <CellStyles disable={!!item.deleted}>
                      {item.createDateTime
                        ? moment.utc(item.createDateTime).format('MMMM Do, YYYY')
                        : moment.utc().format('MMMM Do, YYYY')}
                    </CellStyles>
                  </TableCell>
                  <TableCell align="center">
                    <CellStyles disable={!!item.deleted}>
                      {item.guests ? item.guests : ''}
                    </CellStyles>
                  </TableCell>
                  <TableCell align="center">
                    <Tooltip title="Delete guest records">
                      <Fab
                        color="error"
                        size="small"
                        disabled={!!item.deleted}
                        onClick={() => onSelectToRemove(item.name)}>
                        <DeleteIcon />
                      </Fab>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <LoadingButton
        loading={isLoading}
        type="submit"
        onClick={handleSubmit(onSubmit)}
        variant="contained"
        sx={{ alignSelf: 'flex-start', marginRight: 1 }}>
        CONTINUE
      </LoadingButton>
      <Button type="button" onClick={goBack}>
        BACK
      </Button>

      <UploadFileForm
        key={`form-extra-info-${isUploadFormOpen}`}
        title="Upload Guests File"
        isOpen={isUploadFormOpen}
        onReject={() => {
          setIsUploadFormOpen(false)
        }}
        isLoading={isLoading || false}
        onAccept={onAccept}
        hideDatePicker
        acceptedFiles={acceptedFiles}
      />

      <ConfirmDialog
        title="Delete Guest Records"
        message="Are you sure you want to remove all extra guest data from this file? "
        onAccept={onAcceptRemove}
        isOpen={isConfirmOpen}
        onReject={() => {
          setIsConfirmOpen(false)
        }}
      />
    </Box>
  )
}

export default UploadGuestExtraData
