export const MAX_INTEGER = 2147483647

export const MAIN_NAV_HEIGHT = 5
export const VOYAGE_BAR_HEIGHT = 5
export const FOOTER_BAR_HEIGHT_PX = 88
export const VOYAGE_INFO_BAR_HEIGHT_PX = 91
export const QUEUE_ID = 1
export const WAIT_MINUTES_BAD = 10
export const WAIT_MINUTES_TOO_BAD = 15

export const bookingBrandData = ['Celebrity', 'Royal', 'Silversea']

export const bookingOriginsData = [
  'Future Cruise Office',
  'The Retreat Lounge',
  'Other Remote Location',
  'Booking Form',
  'Brochure App',
  'Celebrity App',
  'Other',
]

export const guestCategoryData = [
  'Traditional Guest',
  'Casino Trade/Direct​',
  'Casino - Casino',
  'Internal',
  'Crew',
  'Incentive / Other Group',
  'Charter',
  'Ship Tour',
  'Recreate booking prior voyage​',
]

export const celebrityStateroomOptions = [
  'Celebrity Suite',
  'Iconic Suite',
  'Reflection Suite',
  'Penthouse Suite',
  'Edge Villa',
  'Royal Suite',
  'Horizon Suite',
  'Signature Suite',
  'Aqua Sky Suite',
  'Sunset Sky Suite',
  'Sky Suite',
  'Magic Carpet Sky Suite',
  'AquaClass',
  'Concierge Class',
  'Veranda',
  'Infinite Veranda',
  'Single Veranda',
  'Deluxe Veranda',
  'Oceanview',
  'Inside',
]

export const royalStateroomOptions = [
  'Royal Loft',
  'Ultimate Family',
  'Villa',
  "Owner's Suite",
  'Grand Suite',
  'Star Loft',
  'AquaTheater Suite',
  'Ultra Panoramic',
  'Crown Loft',
  'Sky Junior',
  'Junior',
  'Solarioum Loft',
  'Balcony',
  'Ocean View',
  'Boardwalk',
  'Central Park',
  'Interior',
]

export const silverSeaStateroomOptions = [
  'Otium Suite',
  "Owner's Suite",
  'Grand Suite',
  'Master Suite',
  'Signature Suite',
  'Royal Suite',
  'Silver Suite',
  'Junior Grand',
  'Medallion Suite',
  'Veranda Suite',
  'Panorama Suite',
  'Vista Suite',
  'View Suite',
]

export const reasonsWhyData = [
  'Guest not ready to plan ship/sailing date',
  'Guest wanted to wait for New Deployment',
  'Guest wanted to wait for a better price/promotion',
  'No guest interaction / turned in booking form / Used app',
  "Booking for Guest's friends and/or family",
  'Additional booking for Guest',
]

export const currencyOptions = ['USD', 'Euro', 'GBP', 'CAD', 'MXN', 'AUD', 'Other']

export const cardStatusOptions = ['APPROVED', 'PENDING', 'DENIED', 'GUEST DECLINED']

export const reasonOptions = [
  'Price',
  'No availability​',
  'Not Comfortable with NRD',
  'Need to talk to family​',
  'Other',
]

export const reasonBookingCancelled = [
  'Deposit not taken - automatic at voyage termination for all offered bookings',
  'Guest Changed Mind​',
  'Guest not ready to put a deposit​',
  'Rebooked – Wrong currency or deposit​',
]

export const marketingInitiativesTypes = [
  'Celebrity Today Front page card',
  'Celebrity Today Inside Page Card​',
  'Celebrity today insert​',
  'Celebrity Today Takeover​',
  'Celebrity APP',
]
