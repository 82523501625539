import { useState } from 'react'
import {
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import TableContainer from '@mui/material/TableContainer'
import { IAgentStatsTableProps, IExpandableRowProps } from './types'
import PortsCardStatsByAgentTable from '../StatsByPort&AgentTable'
import { headTableStyle } from '../../../../styles/generic-styles'
import { theme } from '../../../../styles/mui-theme'
import { useAppSelector } from '../../../../redux/hooks'

const ExpandableRow: React.FC<IExpandableRowProps> = ({ stat, isHighlighted = false }) => {
  const [open, setOpen] = useState(false)

  const cellStyles = isHighlighted
    ? {
        fontWeight: 'bold',
        background: theme.palette.divider,
      }
    : null

  return (
    <>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell sx={cellStyles}>
          <IconButton size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row" sx={cellStyles}>
          {stat.fullName}
        </TableCell>
        <TableCell align="center" sx={cellStyles}>
          {stat.appointmentsUsaGuests || 'None'}
        </TableCell>
        <TableCell align="center" sx={cellStyles}>
          {stat.cards || 'None'}
        </TableCell>
        <TableCell align="center" sx={cellStyles}>
          {stat.cardsApproved || 'None'}
        </TableCell>
        <TableCell align="center" sx={cellStyles}>
          {stat.cardsPending || 'None'}
        </TableCell>
        <TableCell align="center" sx={cellStyles}>
          {stat.cardsDenied || 'None'}
        </TableCell>
        <TableCell align="center" sx={cellStyles}>
          {stat.cardsDeclined || 'None'}
        </TableCell>
        <TableCell align="center" sx={cellStyles}>
          {stat.cardsTransferredToFolio || 'None'}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={10}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <PortsCardStatsByAgentTable stats={stat.agentStats} />
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}

const StatsByAgentTable: React.FC<IAgentStatsTableProps> = ({ stats }) => {
  const loggedUser = useAppSelector((state) => state.auth.loggedUser)
  const currentAgent = stats.find((agent) => agent.userId === loggedUser?.userId)

  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell sx={headTableStyle} />
            <TableCell sx={headTableStyle}>AGENT</TableCell>
            <TableCell sx={headTableStyle} align="center">
              QUALIFIED APPOINTMENTS
            </TableCell>
            <TableCell sx={headTableStyle} align="center">
              OFFERED
            </TableCell>
            <TableCell sx={headTableStyle} align="center">
              APPROVED
            </TableCell>
            <TableCell sx={headTableStyle} align="center">
              PENDING
            </TableCell>
            <TableCell sx={headTableStyle} align="center">
              DENIED
            </TableCell>
            <TableCell sx={headTableStyle} align="center">
              GUEST DECLINED
            </TableCell>
            <TableCell sx={headTableStyle} align="center">
              TRANSFERRED TO FOLIO
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {/* eslint-disable-next-line no-nested-ternary */}
          {loggedUser?.role === 'superadmin' || loggedUser?.role === 'admin' ? (
            stats.map((stat, index) => (
              <ExpandableRow
                key={`row-${index}`}
                stat={stat}
                isHighlighted={index === stats.length - 1}
              />
            ))
          ) : currentAgent ? (
            <ExpandableRow stat={currentAgent} />
          ) : null}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
export default StatsByAgentTable
