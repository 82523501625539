import { useState } from 'react'
import moment from 'moment-timezone'
import { Box, Button, Fab, Stack, Tooltip } from '@mui/material'
import { GridColDef } from '@mui/x-data-grid'
import DeleteIcon from '@mui/icons-material/Delete'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import { LoadingButton } from '@mui/lab'
import { useForm } from 'react-hook-form'
import {
  EConfirmInitiativeType,
  IConfirmDialogProps,
  IInitiative,
  IInitiativePayload,
  IMarketingInitiativesFormProps,
} from './types'
import { CellStyles } from './styles'

import ConfirmDialog from '../ConfirmDialog'
import InitiativeForm from '../InitiativeForm'
import { IInitiativeFormData } from '../../types/cruises'
import DynamicDataGrid from '../DynamicDataGrid'

const MarketingInitiativesForm: React.FC<IMarketingInitiativesFormProps> = ({
  products,
  vessels,
  embarkDate,
  debarkDate,
  initiatives,
  isLoading,
  onSubmit,
  onAddNewInitiative,
  goBack,
}) => {
  const { handleSubmit } = useForm<IInitiativeFormData>()

  const [initiative, setInitiative] = useState<IInitiative | undefined>()
  const [isOpenInitiativeDialog, setIsOpenInitiativeDialog] = useState(false)

  const [isConfirmOpen, setIsConfirmOpen] = useState(false)
  const [confirmProps, setConfirmProps] = useState<IConfirmDialogProps>()

  const confirmDialogDrivers = (row: IInitiativePayload) => ({
    [EConfirmInitiativeType.REMOVE_INITIATIVE]: {
      title: 'Delete Initiative',
      message: 'Are you sure you want to remove this initiative?',
      onAccept: async () => {
        const newItem = {
          initiative_id: row.id,
          initiative_type: row.type,
          product_id: row.productId,
          product: row.productName,
          ship_id: row.shipId,
          ship_name: row.shipName,
          date: row.date,
          deleted: true,
        }
        onAddNewInitiative(newItem)
        setIsConfirmOpen(false)
      },
    },
  })

  const createInitiative = () => {
    setInitiative(undefined)
    setIsOpenInitiativeDialog(true)
  }

  const openConfirmDialog = (option: EConfirmInitiativeType, row: IInitiativePayload) => {
    setIsConfirmOpen(true)
    setConfirmProps(confirmDialogDrivers(row)[option])
  }

  const columns: GridColDef[] = [
    {
      field: 'initiative_type',
      headerName: 'INITIATIVE',
      flex: 2,
      cellClassName: 'initiative-cell',
      renderCell: ({ row }) => (
        <CellStyles m="1rem 0" disable={!!row.deleted}>
          {row.type}
        </CellStyles>
      ),
    },
    {
      field: 'product',
      headerName: 'PRODUCT',
      flex: 2,
      renderCell: ({ row }) => (
        <CellStyles m="1rem 0" disable={!!row.deleted}>
          {row.productName}
        </CellStyles>
      ),
    },
    {
      field: 'ship',
      headerName: 'SHIP',
      flex: 1,
      renderCell: ({ row }) => <CellStyles disable={!!row.deleted}>{row.shipName}</CellStyles>,
    },
    {
      field: 'date',
      headerName: 'DATE',
      width: 80,
      align: 'center',
      headerAlign: 'center',
      renderCell: ({ row }) => (
        <CellStyles disable={!!row.deleted}>{moment(row.date).format('MMM D')}</CellStyles>
      ),
    },
    {
      field: 'actions',
      headerName: '',
      align: 'right',
      width: 180,
      sortable: false,
      renderCell: ({ row }) => (
        <Tooltip title="Delete Initiative">
          <Fab
            color={row.deleted ? 'default' : 'error'}
            size="small"
            disabled={!!row.deleted}
            onClick={() => openConfirmDialog(EConfirmInitiativeType.REMOVE_INITIATIVE, row)}>
            <DeleteIcon />
          </Fab>
        </Tooltip>
      ),
    },
  ]

  return (
    <Box component="form" noValidate>
      <Stack direction="row" justifyContent="space-between" sx={{ pb: 2 }}>
        <Box>
          <Button
            variant="contained"
            color="success"
            startIcon={<AddCircleOutlineIcon />}
            onClick={createInitiative}>
            New Initiative
          </Button>
        </Box>
      </Stack>

      <DynamicDataGrid
        rows={initiatives || []}
        columns={columns}
        isLoading={false}
        hideFooter
        emptyMessage="No initiatives created yet."
      />
      <Box sx={{ marginBottom: 2 }}>
        <InitiativeForm
          key={`form-initiative-${initiative?.initiative_id || isOpenInitiativeDialog}`}
          isOpen={isOpenInitiativeDialog}
          initiative={initiative}
          embarkDate={embarkDate}
          debarkDate={debarkDate}
          vessels={vessels || []}
          products={products || []}
          onAccept={(item) => {
            onAddNewInitiative(item)
            setIsOpenInitiativeDialog(false)
          }}
          onReject={() => setIsOpenInitiativeDialog(false)}
        />
      </Box>

      <LoadingButton
        loading={isLoading}
        type="submit"
        onClick={handleSubmit(onSubmit)}
        variant="contained"
        sx={{ alignSelf: 'flex-start', marginRight: 1 }}>
        CONTINUE
      </LoadingButton>
      <Button type="button" onClick={goBack}>
        BACK
      </Button>

      <ConfirmDialog
        title={confirmProps?.title!}
        message={confirmProps?.message}
        onAccept={confirmProps?.onAccept!}
        onReject={() => {
          setIsConfirmOpen(false)
        }}
        isOpen={isConfirmOpen}
      />
    </Box>
  )
}

export default MarketingInitiativesForm
