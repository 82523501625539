import Divider from '@mui/material/Divider'
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import SettingsIcon from '@mui/icons-material/Settings'
import moment from 'moment'
import { IAppointmentLogsProps } from './types'
import { theme } from '../../styles/mui-theme'

const AppointmentLogs: React.FC<IAppointmentLogsProps> = ({ logs }) => (
  <Paper elevation={3} style={{ marginBottom: 2, maxHeight: '23vh', overflow: 'auto' }}>
    {logs.map((log) => (
      <Stack direction="row" sx={{ mt: 2 }} key={`log-tab-${log.log_id}`}>
        <Stack width={50} marginLeft={1} alignItems="center">
          <SettingsIcon
            sx={{
              color: theme.palette.custom.gray,
              mr: 0,
              pr: 0,
            }}
            fontSize="small"
          />
        </Stack>
        <Stack sx={{ width: 1 }}>
          <Typography fontWeight="bold" fontSize={14}>
            {log.user.first_name || log.user.last_name
              ? `${log.user.first_name} ${log.user.last_name}`
              : 'System'}{' '}
            {log.user.username ? `(${log.user.username})` : ''} on {log.person.person_first_name}{' '}
            {log.person.person_last_name}
            &apos;s appointment
          </Typography>
          <Typography fontSize={12} variant="caption" color={theme.palette.custom.main}>
            {moment(log.recorded_on).format('MMM Do, YYYY h:mm A ')}
          </Typography>
          <Typography fontSize={14} sx={{ mb: 1 }}>
            {log.comment || ''}
          </Typography>
          <Divider />
        </Stack>
      </Stack>
    ))}
  </Paper>
)

export default AppointmentLogs
