export interface ICardPayload {
  appointmentId?: number
  status?: string
  comment?: string
  transferredCard?: boolean
  cardId?: number
  agent_id?: string
  person_id?: number
  room?: number
  first_name?: string
  last_name?: string
  cruise_id?: number
}

export interface ICardStatusUpdatePayload {
  cardId: number
  status: EStatusCard
}

export interface ICardTransferredUpdatePayload {
  cardId: number
  transferredCard: boolean
}

export enum EStatusCard {
  PENDING = 'PENDING',
  APPROVED = 'APPROVED',
  DENIED = 'DENIED',
  GUEST_DECLINED = 'GUEST DECLINED',
}
