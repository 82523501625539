import * as yup from 'yup'

export const getSchemaValidation = (enableGroup?: boolean): yup.AnyObjectSchema => {
  const schema = yup.object().shape({
    bookingBrand: yup.string().required('Brand is required'),
    guestCategory: yup.string().required('Guest Category is required'),
    status: yup.string().required('Status is required'),
    bookingOrigin: yup.string().required('Booking origin is required'),
    isBig: yup.boolean(),
    bookingNumber: yup.mixed().when('status', {
      is: 'cancelled',
      then: () => yup.mixed().notRequired(),
      otherwise: () =>
        yup
          .string()
          .required('Booking number is required')
          .matches(/^\d{1,7}-?\d{0,6}$/, 'Invalid format. Use up to 7 digits with optional dash.')
          .test('Booking number should be less or equal than 7 digits', (value) => {
            if (!value) return true
            const numericLength = value.replace(/-/g, '').length
            return numericLength <= 7
          }),
    }),
  })

  if (enableGroup) {
    return yup.object().shape({
      bookingBrand: yup.string().required('Brand is required'),
      guestCategory: yup.string().required('Guest Category is required'),
      productId: yup.string().required('Product is required'),
      vesselId: yup.string().required('Vessel is required'),
      status: yup.string().required('Status is required'),
      bookingOrigin: yup.string().required('Booking Origin is required'),
      numberOfBookings: yup.string().required('Number of Bookings is required'),
      groupShellNumber: yup.string().required('Group Shell Number is required'),
    })
  }

  return schema
}
