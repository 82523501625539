import { Control, FormState } from 'react-hook-form'
import type { INewVoyageData } from '../../types/cruises'
import { IDestination, IDestinationSimple } from '../../types/destinations'
import { IPort } from '../../types/ports'
import { IShip, IShipSimple } from '../../types/ships'
import { IUploadFileForm } from '../UploadFileForm/types'

export interface IBasicDataFormProps {
  products: IDestinationSimple[]
  vessels: IShipSimple[]
  ports: IPort[]
  values: IBasicDataFormData
  hasAppointments?: boolean
  onSubmit: (basicDataFormData: IBasicDataFormData) => void
}

export interface IBasicDataFormData {
  vessel: number
  product: number
  name: string
  target: number
  voyage_number: number
  sailed_bookings: number
  penetration_goal: number
  embarkPort: number
  embarkDate: string
  embarkTime: string
  debarkPort: number
  debarkDate: string
  debarkTime: string
}

export interface IItineraryFormProps {
  ports: IPort[]
  itinerary: INewVoyagePortInfo[]
  goBack: () => void
  onSubmit: (itineraryFormData: IItineraryFormData) => void
  editPort?: (portId: number) => void
}

export interface INewVoyagePortInfo {
  port: number
  date: string
  startTime: string
  endTime: string
  canModify?: boolean
  hasAppointments?: boolean
  numberOfAppointments?: number
  timezone?: string
  cruisePortId?: number
}

export interface IGuestFilePayload {
  id?: number
  file?: (File | string)[]
  guests?: number
  createDateTime?: string
  name: string
  deleted?: boolean
}

export interface IInitiative {
  initiative_id: number
  initiative_type: string
  product?: string
  product_id: number
  ship_id: number
  ship_name?: string
  date: string
  cruise_id?: number
  deleted?: boolean
}

export interface IInitiativePayload {
  id: number
  type: string
  productName?: string
  productId: number
  shipId: number
  shipName?: string
  date: string
  deleted?: boolean
}

export interface IItineraryFormData {
  portSequence: INewVoyagePortInfo[]
}

export interface IOfficeHoursFormProps {
  ports: IPort[]
  officeHours: INewVoyageOfficeHours[]
  onSubmit: (officeHoursFormData: IOfficeHoursFormData) => void
  goBack: () => void
}

export interface IOfficeHoursFormData {
  officeHours: INewVoyageOfficeHours[]
}

export interface INewVoyageOfficeHours {
  date?: string
  port?: number
  hours: {
    openHour: string
    closeHour: string
  }[]
}

export interface INestedOfficeHoursFormProps {
  control: Control<IOfficeHoursFormData, any>
  index: number
  formState: FormState<IOfficeHoursFormData>
  setTimes: (index: number, itemIndex: number) => void
}

export interface ICallbackTimeLimitsFormProps {
  ports: IPort[]
  callbacks: INewVoyageCallbackTimeLimits[]
  isLoading?: boolean
  onSubmit: (callbackTimeLimitsFormData: ICallbackTimeLimitsFormData) => void
  setStateCallbacks: (value: React.SetStateAction<INewVoyageData>) => void
  goBack: () => void
}

export interface ICallbackTimeLimitsFormData {
  callbacks: INewVoyageCallbackTimeLimits[]
}

export interface INewVoyageCallbackTimeLimits {
  date: string
  port: number
  hours: {
    openHour: string
    closeHour: string
    callbacksPerHour: number
  }[]
}

export interface INestedCallbackTimesFormProps {
  control: Control<ICallbackTimeLimitsFormData, any>
  index: number
  hoursOptions: {
    openHour: string
    closeHour: string
  }[]
  formState: FormState<ICallbackTimeLimitsFormData>
  setTimes: (
    index: number,
    itemIndex: number,
    openHourValue: string,
    closeHourValue: string,
  ) => void
  setCallbacksPerHour: (index: number, itemIndex: number, value: number) => void
}

export interface IGuestExtraDataFormProps {
  files: IGuestFilePayload[]
  isLoading?: boolean
  onRemoveFile: (file: string) => void
  onSubmit: () => void
  onUploadFile: (UploadFileForm: IUploadFileForm) => void
  goBack: () => void
}

export interface IMarketingInitiativesFormProps {
  vessels: IShip[]
  products: IDestination[]
  embarkDate: string
  debarkDate: string
  initiatives: IInitiativePayload[]
  isLoading?: boolean
  onSubmit: () => void
  onAddNewInitiative: (initiative: IInitiative) => void
  goBack: () => void
}

export interface IConfirmDialogProps {
  title: string
  message: string
  onAccept: () => void
}

export enum EConfirmInitiativeType {
  REMOVE_INITIATIVE,
}
