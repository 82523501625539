import Stack from '@mui/material/Stack'
import Chip from '@mui/material/Chip'
import { IconButton, Tooltip } from '@mui/material'
import EmojiFlagsOutlinedIcon from '@mui/icons-material/EmojiFlagsOutlined'
import SmartphoneIcon from '@mui/icons-material/SmartphoneOutlined'
import { IActionsColumnProps } from './types'
import { theme } from '../../styles/mui-theme'
import { EBookingType } from '../../types/bookings'

const PreviousColumn: React.FC<IActionsColumnProps> = ({ appointment, openDialog }) => {
  const appointmentId = appointment.appointment_id

  const hasPendingTasks = appointment.bookings
    ? appointment.bookings.filter(
        (item) => item.appointment_id === appointment.appointment_id && item.pending_items === 1,
      ).length > 0
    : false

  const bookingsCounter: number = appointment.bookings
    ? appointment.bookings.filter((item) => item.appointment_id !== appointmentId).length
    : 0
  const quotesCounter = appointment.quotes
    ? appointment.quotes.filter((item) => item.appointment_id !== appointmentId).length
    : 0

  // OWN BOOKINGS
  const ownAppBooking =
    appointment.bookings?.filter(
      (booking) =>
        booking.booking_type === EBookingType.APP_BOOKING && booking.isFromThisAppointment,
    ) ?? []

  return (
    <Stack direction="row" spacing={1} alignItems="center">
      {ownAppBooking.length > 0 && (
        <SmartphoneIcon
          sx={{
            color: theme.palette.custom.tangerine,
          }}
          fontSize="small"
        />
      )}
      {bookingsCounter > 0 ? (
        <Chip
          label={bookingsCounter}
          sx={{ backgroundColor: theme.palette.primary.light, color: 'white' }}
        />
      ) : null}
      {quotesCounter > 0 ? (
        <Chip
          label={quotesCounter}
          sx={{ backgroundColor: theme.palette.custom.yellow, color: 'white' }}
        />
      ) : null}
      {appointment.events && appointment.events > 0 ? (
        <Chip
          label={appointment.events}
          sx={{ backgroundColor: theme.palette.success.main, color: 'white' }}
        />
      ) : null}
      {appointment.mailDrop && appointment.mailDrop > 0 ? (
        <Chip
          label={appointment.mailDrop}
          sx={{ backgroundColor: theme.palette.error.main, color: 'white' }}
        />
      ) : null}
      {appointment.cards && appointment.cards.length > 0 ? (
        <Chip label="V" sx={{ backgroundColor: theme.palette.secondary.main, color: 'white' }} />
      ) : null}
      {hasPendingTasks && (
        <Tooltip title="This appointment has pending items.">
          <IconButton
            onClick={(e) => {
              openDialog(appointment)
              e.stopPropagation()
            }}>
            <EmojiFlagsOutlinedIcon
              sx={{
                color: theme.palette.custom.yellow,
              }}
              fontSize="medium"
            />
          </IconButton>
        </Tooltip>
      )}
    </Stack>
  )
}

export default PreviousColumn
