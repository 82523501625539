import { IGuestFilePayload, IInitiative } from '../../../components/VoyageForm/types'
import { IDestinationSimple } from '../../../types/destinations'
import { IPort } from '../../../types/ports'
import { IShipSimple } from '../../../types/ships'
import { EArchiveOperation } from '../ProductsVessels/types'

export interface IGetCruisesResponse {
  cruise_id: number
  ship: IShipSimple
  destination: IDestinationSimple
  start_port: number
  start_date: string
  end_port: number
  end_date: string
  active: number
  voyage_number: number
  sailed_bookings: number
  penetration_goal: number
  description: string
  voyage_target: number
  startPort: IPort
  endPort: IPort
  port_sequence: IPortSequence[]
  office_hours: IOfficeHour[]
  agents_count: IAgentsCount[]
  callback_times: ICallBackTime[]
  files: IGuestFilePayload[]
  initiatives: IInitiative[]
  feedback: IFeedback
}

export interface IPortSequence {
  cruise_port_id: number
  cruise_id: number
  port_id: number
  port_name: string
  arrival_date: Date
  departure_date: Date
  name: string
  isDeleted: number
  timezone: string
  timezoneoffset: string
  hasAppointments: boolean
  numberOfAppointments?: number
}

export interface IOfficeHour {
  callback_time_id: number
  cruise_id: number
  date: string
  open_hour: string
  close_hour: string
  max_callbacks_per_hour: number
}

export interface IAgentsCount {
  cruise_id: number
  user_id: string
  code: string
  first_name: string
  last_name: string
}

export interface ICallBackTime {
  callback_time_id: number
  cruise_id: number
  date: Date
  open_hour: string
  close_hour: string
  max_callbacks_per_hour: number
  port_name: string
}

export interface IActiveInactiveCruisePayload {
  action: string
  cruiseId: number
}

export interface IArchiveProductPayload {
  id: number
  action: EArchiveOperation
}

export interface IGetDaysNotAvailableResponse {
  success: boolean
  dates_configured: boolean
  dates_not_available: string[]
  end_date: string
  no_callbacks_available_in_voyage: boolean
  start_date: string
}

export interface IGetOfficeHoursResponse {
  success: boolean
  message: string
  data: IHours[]
}

export interface IHours {
  date: string
  value: string
  label: string
}

export interface ISocketVoyageResponse {
  message: string
  userId: string
  isActive: boolean
}

export interface IFeedbackValues {
  id?: number
  cruise_id: number
  question: string
  answer: string
}
export interface IFeedback {
  successful_initiatives: IFeedbackValues[]
  challenges_identified: IFeedbackValues[]
  guest_reviews: IFeedbackValues[]
}

export interface IFeedbackPayload {
  feedback: IFeedback
  cruiseId: number
}

export enum EFeedbackQuestions {
  SUCCESSFUL_INITIATIVES = 'successful_initiatives',
  CHALLENGES_IDENTIFIED = 'challenges_identified',
  GUEST_REVIEWS = 'guest_reviews',
}
