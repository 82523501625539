import React from 'react'
import { Box } from '@mui/material'
import { styled } from '@mui/system'
import { ImageProps, ImageStrokeProgressProps, OverlayProps } from './types'

const ProgressWrapper = styled(Box)(() => ({
  position: 'relative',
  width: '100%',
  height: 56,
  borderRadius: 5,
  overflow: 'hidden',
}))

const BaseImage = styled('div')<ImageProps>(({ image }) => ({
  backgroundImage: `url(${image})`,
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  width: '100%',
  height: '100%',
  position: 'absolute',
}))

const ColoredOverlay = styled('div')<OverlayProps>(({ progress, image, color }) => ({
  width: '100%',
  height: '100%',
  background: `linear-gradient(to right, ${color} ${progress}%, transparent ${progress}%)`,
  maskImage: `url(${image})`,
  maskSize: 'cover',
  maskRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  transition: 'background 0.3s ease-in-out',
  position: 'absolute',
}))

const Logo = styled('div')(() => ({
  position: 'absolute',
  top: '63%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  zIndex: 2,
  width: '40%',
  height: '40%',
  backgroundImage: `url('/images/logo.png')`,
  backgroundSize: 'contain',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
}))

const ImageStrokeProgress: React.FC<ImageStrokeProgressProps> = ({ imageUrl, value, color }) => (
  <ProgressWrapper>
    <BaseImage image={imageUrl} />
    <ColoredOverlay progress={value} image={imageUrl} color={color} />
    <Logo />
  </ProgressWrapper>
)

export default ImageStrokeProgress
