import {
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material'
import numeral from 'numeral'
import TableContainer from '@mui/material/TableContainer'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { useState } from 'react'
import { IAgentStatsTableProps, IExpandableRowProps } from './types'
import {
  headTableStyle,
  headTableBlueStyle,
  headTableDarkGreenStyle,
  headTableGreenStyle,
  headTableTangerineStyle,
  cellBorderRight,
} from '../../../styles/generic-styles'
import { theme } from '../../../styles/mui-theme'
import { useAppSelector } from '../../../redux/hooks'
import { convertSecondsToMinutesSeconds } from '../../../utils/time'
import PortStatsByAgentTable from '../PortAgentStatsTable'

const ExpandableRow: React.FC<IExpandableRowProps> = ({ stat, isHighlighted = false }) => {
  const [open, setOpen] = useState(false)
  const totalStandardBookings =
    (stat.bookingOfficeNow || 0) +
    (stat.bookingOfficeLater || 0) +
    (stat.bookingRemoteNow || 0) +
    (stat.bookingRemoteLater || 0) +
    (stat.bookingSelfService || 0)
  const cellStyles = isHighlighted
    ? {
        fontWeight: 'bold',
        background: theme.palette.divider,
      }
    : null

  return (
    <>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell sx={cellStyles}>
          <IconButton size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row" sx={{ ...cellStyles, ...cellBorderRight }}>
          {stat.fullName}
        </TableCell>
        <TableCell align="center" sx={{ ...cellStyles, ...cellBorderRight }}>
          {stat.qualifiedAppointments || 'None'}
        </TableCell>
        <TableCell align="center" sx={{ ...cellStyles, ...cellBorderRight }}>
          {numeral(stat.conversion).format('0,0.00%')}
        </TableCell>
        <TableCell align="center" sx={{ ...cellStyles, ...cellBorderRight }}>
          {stat.averageApptTime && stat.averageApptTime > 0
            ? `${convertSecondsToMinutesSeconds(stat.averageApptTime)} min`
            : 0}
        </TableCell>
        <TableCell align="center" sx={{ ...cellStyles, ...cellBorderRight }}>
          {stat.totalOverall || 'None'}
        </TableCell>
        <TableCell align="center" sx={{ ...cellStyles, ...cellBorderRight }}>
          {stat.bookingOfficeNow || 'None'}
        </TableCell>
        <TableCell align="center" sx={{ ...cellStyles, ...cellBorderRight }}>
          {stat.bookingOfficeLater || 'None'}
        </TableCell>
        <TableCell align="center" sx={{ ...cellStyles, ...cellBorderRight }}>
          {stat.bookingRemoteNow || 'None'}
        </TableCell>
        <TableCell align="center" sx={{ ...cellStyles, ...cellBorderRight }}>
          {stat.bookingRemoteLater || 'None'}
        </TableCell>
        <TableCell align="center" sx={{ ...cellStyles, ...cellBorderRight }}>
          {stat.bookingSelfService || 'None'}
        </TableCell>
        <TableCell align="center" sx={{ ...cellStyles, ...cellBorderRight }}>
          {totalStandardBookings || 0}
        </TableCell>
        <TableCell align="center" sx={{ ...cellStyles, ...cellBorderRight }}>
          {stat.bookingSupplemental || 'None'}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={13}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <PortStatsByAgentTable stats={stat.agentStats} />
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}

const AgentStatsTable: React.FC<IAgentStatsTableProps> = ({ stats }) => {
  const loggedUser = useAppSelector((state) => state.auth.loggedUser)
  const currentAgent = stats.find((agent) => agent.userId === loggedUser?.userId)

  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell rowSpan={2} sx={headTableStyle} />
            <TableCell rowSpan={2} sx={{ ...headTableStyle, ...cellBorderRight }} align="center">
              Agent
            </TableCell>
            <TableCell rowSpan={2} sx={{ ...headTableStyle, ...cellBorderRight }} align="center">
              Total Qualified Appts
            </TableCell>
            <TableCell rowSpan={2} sx={{ ...headTableStyle, ...cellBorderRight }} align="center">
              Conversion
            </TableCell>
            <TableCell rowSpan={2} sx={{ ...headTableStyle, ...cellBorderRight }} align="center">
              Average Appt Time
            </TableCell>
            <TableCell
              rowSpan={2}
              sx={{ ...headTableBlueStyle, ...cellBorderRight }}
              align="center">
              Total Overall Appts
            </TableCell>
            <TableCell
              colSpan={2}
              sx={{ ...headTableGreenStyle, ...cellBorderRight }}
              align="center">
              Office Bookings
            </TableCell>
            <TableCell
              colSpan={2}
              sx={{ ...headTableGreenStyle, ...cellBorderRight }}
              align="center">
              Remote Bookings
            </TableCell>
            <TableCell
              rowSpan={2}
              sx={{ ...headTableGreenStyle, ...cellBorderRight }}
              align="center">
              Self Service Bookings
            </TableCell>
            <TableCell
              rowSpan={2}
              sx={{ ...headTableDarkGreenStyle, ...cellBorderRight }}
              align="center">
              Total
            </TableCell>
            <TableCell
              rowSpan={2}
              sx={{ ...headTableTangerineStyle, ...cellBorderRight }}
              align="center">
              Supplemental Bookings
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="center" sx={headTableGreenStyle}>
              BOOK NOW
            </TableCell>
            <TableCell align="center" sx={{ ...headTableGreenStyle, ...cellBorderRight }}>
              BOOK LATER
            </TableCell>
            <TableCell align="center" sx={headTableGreenStyle}>
              BOOK NOW
            </TableCell>
            <TableCell align="center" sx={{ ...headTableGreenStyle, ...cellBorderRight }}>
              BOOK LATER
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {/* eslint-disable-next-line no-nested-ternary */}
          {loggedUser?.role === 'superadmin' || loggedUser?.role === 'admin' ? (
            stats.map((stat, index) => (
              <ExpandableRow
                key={`row-${index}`}
                stat={stat}
                isHighlighted={index === stats.length - 1}
              />
            ))
          ) : currentAgent ? (
            <ExpandableRow stat={currentAgent} />
          ) : null}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
export default AgentStatsTable
