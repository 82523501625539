import { useEffect, useState } from 'react'
import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Autocomplete from '@mui/material/Autocomplete'
import Button from '@mui/material/Button'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { Moment } from 'moment'
import Alert from '@mui/material/Alert'
import { InputAdornment } from '@mui/material'
import { IBasicDataFormData, IBasicDataFormProps } from './types'
import { basicDataValidation } from './validation'
import { IPort } from '../../types/ports'
import { IDestinationSimple } from '../../types/destinations'
import { IShipSimple } from '../../types/ships'
import { MAX_INTEGER } from '../../utils/constants'

const BasicDataForm: React.FC<IBasicDataFormProps> = ({
  products,
  vessels,
  ports,
  values,
  hasAppointments,
  onSubmit,
}) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm<IBasicDataFormData>({
    defaultValues: values,
    resolver: yupResolver(basicDataValidation),
  })

  const [embarkDate, setEmbarkDate] = useState<Moment | null>(null)
  const [debarkDate, setDebarkDate] = useState<Moment | null>(null)
  const [vesselValue, setVesselValue] = useState<IShipSimple | null>(
    vessels.filter((vessel) => vessel.id === values.vessel)[0] ?? null,
  )
  const [productValue, setProductValue] = useState<IDestinationSimple | null>(
    products.filter((product) => product.id === values.product)[0] ?? null,
  )
  const [embarkPortValue, setEmbarkPortValue] = useState<IPort | null>(
    ports.filter((port) => port.port_id === values.embarkPort)[0] ?? null,
  )
  const [debarkPortValue, setDebarkPortValue] = useState<IPort | null>(
    ports.filter((port) => port.port_id === values.debarkPort)[0] ?? null,
  )

  useEffect(() => {
    if (values.name) {
      reset(values)
      setVesselValue(vessels.filter((vessel) => vessel.id === values.vessel)[0])
      setProductValue(products.filter((product) => product.id === values.product)[0])
      setEmbarkPortValue(ports.filter((port) => port.port_id === values.embarkPort)[0])
      setDebarkPortValue(ports.filter((port) => port.port_id === values.debarkPort)[0])
    }
  }, [values])

  return (
    <Box
      component="form"
      noValidate
      onSubmit={handleSubmit(onSubmit)}
      sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Grid container spacing={2} sx={{ marginBottom: 2 }}>
        <Grid item xs={6}>
          <Controller
            control={control}
            name="vessel"
            render={({ field: { onChange, value, ...field } }) => (
              <Autocomplete
                options={vessels}
                onChange={(_, data) => {
                  onChange(data?.id)
                  setVesselValue(data)
                }}
                getOptionLabel={(vessel) => vessel.name}
                value={vesselValue}
                renderOption={(props, option) => (
                  <Box {...props} component="li" key={option.id} value={option.id}>
                    {option.name}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    {...field}
                    label="Vessel"
                    fullWidth
                    error={!!errors.vessel}
                    helperText={errors?.vessel?.message as string}
                  />
                )}
              />
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <Controller
            control={control}
            name="product"
            render={({ field: { onChange, value, ...field } }) => (
              <Autocomplete
                options={products}
                onChange={(_, data) => {
                  onChange(data?.id)
                  setProductValue(data)
                }}
                getOptionLabel={(product) => product.name}
                value={productValue}
                renderOption={(props, option) => (
                  <Box {...props} component="li" key={option.id} value={option.id}>
                    {option.name}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    {...field}
                    label="Product"
                    fullWidth
                    error={!!errors.product}
                    helperText={errors?.product?.message as string}
                  />
                )}
              />
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <Controller
            control={control}
            name="name"
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                label="Itinerary Name"
                fullWidth
                error={!!errors.name}
                helperText={errors?.name?.message as string}
              />
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <Controller
            control={control}
            name="voyage_number"
            render={({ field }) => (
              <TextField
                {...field}
                type="number"
                label="Voyage Number"
                fullWidth
                error={!!errors.voyage_number}
                helperText={errors?.voyage_number?.message as string}
              />
            )}
          />
        </Grid>
        <Grid item xs={4}>
          <Controller
            control={control}
            name="target"
            defaultValue={0}
            render={({ field }) => (
              <TextField
                {...field}
                id="target"
                label="Goal"
                type="number"
                InputProps={{
                  inputProps: { max: MAX_INTEGER, min: 1 },
                }}
                fullWidth
                error={!!errors.target}
                helperText={errors?.target?.message as string}
              />
            )}
          />
        </Grid>
        <Grid item xs={4}>
          <Controller
            control={control}
            name="sailed_bookings"
            render={({ field }) => (
              <TextField
                {...field}
                type="number"
                label="Sailed Bookings"
                fullWidth
                error={!!errors.sailed_bookings}
                helperText={errors?.sailed_bookings?.message as string}
              />
            )}
          />
        </Grid>
        <Grid item xs={4}>
          <Controller
            control={control}
            name="penetration_goal"
            defaultValue={0}
            render={({ field }) => (
              <TextField
                {...field}
                id="penetration_goal"
                label="Penetration Guidance"
                type="number"
                InputProps={{
                  startAdornment: <InputAdornment position="start">%</InputAdornment>,
                  inputProps: { max: MAX_INTEGER, min: 1 },
                }}
                fullWidth
                error={!!errors.penetration_goal}
                helperText={errors?.penetration_goal?.message as string}
              />
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <Controller
            control={control}
            name="embarkPort"
            render={({ field: { onChange, value, ...field } }) => (
              <Autocomplete
                options={ports}
                onChange={(_, data) => {
                  onChange(data?.port_id)
                  setEmbarkPortValue(data)
                }}
                disabled={hasAppointments}
                value={embarkPortValue}
                getOptionLabel={(port) => port.name}
                renderOption={(props, option) => (
                  <Box {...props} component="li" key={option.port_id} value={option.port_id}>
                    {option.name}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    {...field}
                    label="Embark Port"
                    fullWidth
                    error={!!errors.embarkPort}
                    helperText={errors?.embarkPort?.message as string}
                  />
                )}
              />
            )}
          />
        </Grid>
        <Grid item xs={3}>
          <Controller
            control={control}
            name="embarkDate"
            render={({ field: { onChange, ...field } }) => (
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  label="Embark Date"
                  maxDate={debarkDate?.clone().subtract(1, 'day')}
                  onChange={(newValue) => {
                    setEmbarkDate(newValue)
                    onChange(newValue)
                  }}
                  disabled={hasAppointments}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={!!errors.embarkDate}
                      helperText={errors?.embarkDate?.message as string}
                    />
                  )}
                  {...field}
                />
              </LocalizationProvider>
            )}
          />
        </Grid>
        <Grid item xs={3}>
          <Controller
            control={control}
            name="embarkTime"
            defaultValue=""
            render={({ field }) => (
              <TextField
                label="Embark Time"
                type="time"
                InputLabelProps={{
                  shrink: true,
                }}
                disabled={hasAppointments}
                fullWidth
                error={!!errors.embarkTime}
                helperText={errors?.embarkTime?.message as string}
                {...field}
              />
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <Controller
            name="debarkPort"
            control={control}
            render={({ field: { onChange, value, ...field } }) => (
              <Autocomplete
                options={ports}
                onChange={(_, data) => {
                  onChange(data?.port_id)
                  setDebarkPortValue(data)
                }}
                getOptionLabel={(port) => port.name}
                value={debarkPortValue}
                disabled={hasAppointments}
                renderOption={(props, option) => (
                  <Box {...props} component="li" key={option.port_id} value={option.port_id}>
                    {option.name}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    {...field}
                    label="Debark Port"
                    fullWidth
                    error={!!errors.debarkPort}
                    helperText={errors?.debarkPort?.message as string}
                  />
                )}
              />
            )}
          />
        </Grid>
        <Grid item xs={3}>
          <Controller
            control={control}
            name="debarkDate"
            render={({ field: { onChange, ...field } }) => (
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  label="Debark Date"
                  minDate={embarkDate?.clone().add(1, 'day')}
                  onChange={(newValue) => {
                    setDebarkDate(newValue)
                    onChange(newValue)
                  }}
                  disabled={hasAppointments}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={!!errors.debarkDate}
                      helperText={errors?.debarkDate?.message as string}
                    />
                  )}
                  {...field}
                />
              </LocalizationProvider>
            )}
          />
        </Grid>
        <Grid item xs={3}>
          <Controller
            control={control}
            name="debarkTime"
            defaultValue=""
            render={({ field }) => (
              <TextField
                label="Debark Time"
                type="time"
                InputLabelProps={{
                  shrink: true,
                }}
                disabled={hasAppointments}
                fullWidth
                {...field}
                error={!!errors.debarkTime}
                helperText={errors?.debarkTime?.message as string}
              />
            )}
          />
        </Grid>
      </Grid>

      {hasAppointments && (
        <Alert sx={{ width: '100%', marginBottom: 2 }} severity="info">
          Embark and debark information can&apos;t be changed because this voyage has appointments.
        </Alert>
      )}

      <Button type="submit" variant="contained" sx={{ alignSelf: 'flex-start' }}>
        CONTINUE
      </Button>
    </Box>
  )
}

export default BasicDataForm
