import { useParams } from 'react-router-dom'
import { saveAs } from 'file-saver'
import { useGetCruiseReportQuery, useLazyGetExcelReportQuery } from '../../../redux/api/reports'
import { useGetCruiseQuery, useUpdateFeedbackMutation } from '../../../redux/api/cruises'
import { IFeedbackPayload } from '../Voyages/types'

export const useReport = () => {
  const { id } = useParams()
  const { isLoading, data, refetch: refetchGetReport } = useGetCruiseReportQuery(Number(id))
  const [getReport, { isFetching: isFetchingDownload }] = useLazyGetExcelReportQuery()
  const [setFeedbackCruise, { isLoading: isLoadingUpdateFeedback }] = useUpdateFeedbackMutation()
  const { data: cruiseDetail, isLoading: isLoadingCruiseDetail, refetch } = useGetCruiseQuery(id!)

  const onDownloadExcel = async (cruiseId: number): Promise<void> => {
    const response = await getReport(cruiseId).unwrap()
    saveAs(`data:application/vnd.ms-excel;base64,${response.file}`, response.fileName)
  }

  const updateFeedback = async (values: IFeedbackPayload): Promise<void> => {
    try {
      await setFeedbackCruise(values).unwrap()
      await refetch()
    } catch (error) {
      console.error(error)
    }
  }

  return {
    isLoading,
    data,
    onDownloadExcel,
    isFetchingDownload,
    refetchGetReport,
    updateFeedback,
    isLoadingUpdateFeedback,
    cruiseDetail,
    isLoadingCruiseDetail,
  }
}
