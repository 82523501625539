import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import StepContent from '@mui/material/StepContent'
import { useEffect, useState } from 'react'
import moment from 'moment-timezone'
import { useNavigate, useParams } from 'react-router-dom'
import { useEditVoyage } from './hook'
import GridSpinner from '../../../../components/GridSpinner'
import BasicDataForm from '../../../../components/VoyageForm/BasicDataForm'
import {
  IBasicDataFormData,
  ICallbackTimeLimitsFormData,
  IInitiative,
  IItineraryFormData,
  INewVoyageCallbackTimeLimits,
  INewVoyageOfficeHours,
  INewVoyagePortInfo,
  IOfficeHoursFormData,
} from '../../../../components/VoyageForm/types'
import { useNewVoyage } from '../NewVoyage/hook'
import ItineraryForm from '../../../../components/VoyageForm/ItineraryForm'
import OfficeHoursForm from '../../../../components/VoyageForm/OfficeHoursForm'
import CallbackTimeLimitsForm from '../../../../components/VoyageForm/CallbackTimeLimitsForm'
import GuestExtraData from '../../../../components/VoyageForm/UploadGuestExtraData'
import { IPort } from '../../../../types/ports'
import PortForm from '../../../../components/PortForm'
import { usePorts } from '../../Ports/hook'
import {
  ICallBackTimeCruisePayload,
  ICreateCruisePayload,
  INewVoyageData,
  IPortSequenceCruisePayload,
} from '../../../../types/cruises'
import SettingsLayout from '../../../../components/SettingsLayout'
import { useVoyages } from '../hook'
import { IUploadFileForm } from '../../../../components/UploadFileForm/types'
import MarketingInitiativesForm from '../../../../components/VoyageForm/MarketingInitiativeForm'

const EditVoyage = () => {
  const { products, vessels, ports, isLoading, create } = useNewVoyage()
  const { data, isLoading: isLoadingGetCruise, refetch } = useEditVoyage()
  const { refetch: refetchVoyages } = useVoyages()
  const { createEdit } = usePorts()
  const [activeStep, setActiveStep] = useState(0)
  const [portToEdit, setPortToEdit] = useState<IPort | undefined>()
  const [isOpenPortDialog, setIsOpenPortDialog] = useState(false)
  const [isLoadingPort, setIsLoadingPort] = useState(false)
  const [formData, setFormData] = useState<INewVoyageData>({
    vessel: 0,
    product: 0,
    name: '',
    target: 0,
    embarkPort: 0,
    embarkDate: '',
    voyage_number: 0,
    penetration_goal: 0,
    sailed_bookings: 0,
    embarkTime: '',
    debarkPort: 0,
    debarkDate: '',
    debarkTime: '',
    portSequence: [],
    officeHours: [],
    callbacks: [],
    files: [],
    initiatives: [],
  })
  const [selectedTimezone, setSelectedTimezone] = useState('')
  const { id } = useParams()
  const navigate = useNavigate()

  useEffect(() => {
    if (data) {
      setFormData({
        vessel: data.vessel,
        product: data.product,
        name: data.name,
        target: data.target,
        voyage_number: data.voyage_number,
        sailed_bookings: data.sailed_bookings,
        penetration_goal: data.penetration_goal,
        embarkPort: data.embarkPort,
        embarkDate: data.embarkDate,
        embarkTime: data.embarkTime,
        debarkPort: data.debarkPort,
        debarkDate: data.debarkDate,
        debarkTime: data.debarkTime,
        portSequence: data.portSequence,
        officeHours: data.officeHours,
        callbacks: data.callbacks,
        files: data.files,
        initiatives: data.initiatives,
      })
    }
  }, [data])

  useEffect(() => {
    if (selectedTimezone) {
      const newPortSequence = formData.portSequence.map((p) => {
        if (p.port === portToEdit?.port_id) {
          return { ...p, timezone: selectedTimezone }
        }

        return p
      })
      setFormData((oldData) => ({ ...oldData, portSequence: newPortSequence }))
    }
  }, [selectedTimezone])

  const onSuccessEditCruise = () => {
    refetchVoyages()
    refetch()
    navigate('/settings/voyages')
  }

  const onSubmitBasicDataForm = (basicDataFormData: IBasicDataFormData) => {
    setFormData((oldData) => ({
      ...oldData,
      ...basicDataFormData,
    }))

    if (!data?.hasAppointments) {
      const difference = moment(basicDataFormData.debarkDate).diff(
        moment(basicDataFormData.embarkDate),
        'days',
      )

      if (
        formData.portSequence[0].port !== basicDataFormData.embarkPort ||
        formData.portSequence[0].startTime !== basicDataFormData.embarkTime ||
        moment(formData.portSequence[0].date).format('MM/DD/YYYY') !==
          moment(basicDataFormData.embarkDate).format('MM/DD/YYYY') ||
        formData.portSequence[formData.portSequence.length - 1].port !==
          basicDataFormData.debarkPort ||
        formData.portSequence[formData.portSequence.length - 1].startTime !==
          basicDataFormData.debarkTime ||
        moment(formData.portSequence[formData.portSequence.length - 1].date).format(
          'MM/DD/YYYY',
        ) !== moment(basicDataFormData.debarkDate).format('MM/DD/YYYY')
      ) {
        const oldPortSequence = [...formData.portSequence]
        oldPortSequence.pop()
        oldPortSequence.shift()

        const itinerary: INewVoyagePortInfo[] = [
          {
            ...formData.portSequence[0],
            date: basicDataFormData.embarkDate,
            port: basicDataFormData.embarkPort,
            startTime: basicDataFormData.embarkTime,
            endTime: formData.portSequence[0].endTime,
          },
        ]

        for (let index = 0; index < difference - 1; index += 1) {
          itinerary.push({
            ...oldPortSequence[index],
            port: oldPortSequence[index] ? oldPortSequence[index].port : NaN,
            date: moment(basicDataFormData.embarkDate)
              .clone()
              .add(index + 1, 'day')
              .toString(),
            startTime: oldPortSequence[index] ? oldPortSequence[index].startTime : '',
            endTime: oldPortSequence[index] ? oldPortSequence[index].endTime : '',
            canModify: true,
          })
        }

        itinerary.push({
          ...formData.portSequence[formData.portSequence.length - 1],
          port: basicDataFormData.debarkPort,
          date: basicDataFormData.debarkDate,
          startTime: formData.portSequence[formData.portSequence.length - 1].startTime,
          endTime: formData.portSequence[formData.portSequence.length - 1].endTime,
        })

        setFormData((oldData) => ({ ...oldData, portSequence: itinerary }))
      }
    }

    setActiveStep((prevStep) => prevStep + 1)
  }

  const onSubmitItineraryForm = (itineraryData: IItineraryFormData) => {
    const { portSequence } = itineraryData
    setFormData((oldData) => ({
      ...oldData,
      portSequence,
    }))

    if (
      (formData.officeHours.length > 2 &&
        !formData.officeHours.every(
          (officeHour, index) => officeHour.port === portSequence[index].port,
        )) ||
      formData.officeHours.length !== portSequence.length
    ) {
      if (data?.hasAppointments) {
        const officeHours: INewVoyageOfficeHours[] = portSequence.map((port, index) => {
          if (index === 0 && formData.officeHours[0].port === portSequence[0].port) {
            return formData.officeHours[0]
          }

          if (
            index === portSequence.length - 1 &&
            formData.officeHours[formData.officeHours.length - 1].port ===
              portSequence[portSequence.length - 1].port
          ) {
            return formData.officeHours[formData.officeHours.length - 1]
          }

          return {
            date: port.date,
            port: port.port,
            hours: [
              {
                openHour: '',
                closeHour: '',
              },
            ],
          }
        })
        setFormData((oldData) => ({ ...oldData!, officeHours }))
      } else {
        const oldOfficeHours = [...formData.officeHours]
        oldOfficeHours.pop()

        const officeHours: INewVoyageOfficeHours[] = portSequence.map((port, index) => {
          if (index === 0 && formData.officeHours[0].port === portSequence[0].port) {
            return {
              ...formData.officeHours[0],
              date: port.date,
            }
          }

          if (index === 0 && formData.officeHours[0].port !== portSequence[0].port) {
            return {
              date: port.date,
              port: port.port,
              hours: [
                {
                  openHour: '',
                  closeHour: '',
                },
              ],
            }
          }

          if (
            index === portSequence.length - 1 &&
            formData.officeHours[formData.officeHours.length - 1].port ===
              portSequence[portSequence.length - 1].port
          ) {
            return {
              ...formData.officeHours[formData.officeHours.length - 1],
              date: port.date,
            }
          }

          if (
            index === portSequence.length - 1 &&
            formData.officeHours[formData.officeHours.length - 1].port !==
              portSequence[portSequence.length - 1].port
          ) {
            return {
              date: port.date,
              port: port.port,
              hours: [
                {
                  openHour: '',
                  closeHour: '',
                },
              ],
            }
          }

          if (oldOfficeHours[index]) {
            return {
              ...formData.officeHours[index],
              date: port.date,
            }
          }

          return {
            date: port.date,
            port: port.port,
            hours: [
              {
                openHour: '',
                closeHour: '',
              },
            ],
          }
        })
        setFormData((oldData) => ({ ...oldData!, officeHours }))
      }
    }

    if (
      (formData.callbacks.length > 2 &&
        !formData.callbacks.every(
          (callback, index) => callback.port === portSequence[index].port,
        )) ||
      formData.callbacks.length !== portSequence.length
    ) {
      if (data?.hasAppointments) {
        const callbacks: INewVoyageCallbackTimeLimits[] = portSequence.map((port, index) => {
          if (
            index === 0 &&
            formData.callbacks[0] &&
            formData.callbacks[0].port === portSequence[0].port
          ) {
            return formData.callbacks[0]
          }

          if (
            index === portSequence.length - 1 &&
            formData.callbacks[formData.callbacks.length - 1].port ===
              portSequence[portSequence.length - 1].port
          ) {
            return formData.callbacks[formData.callbacks.length - 1]
          }

          return {
            date: port.date,
            port: port.port,
            hours: [
              {
                openHour: '',
                closeHour: '',
                callbacksPerHour: 0,
              },
            ],
          }
        })
        setFormData((oldData) => ({ ...oldData!, callbacks }))
      } else {
        const oldCallbacks = [...formData.callbacks]
        oldCallbacks.pop()

        const callbacks: INewVoyageCallbackTimeLimits[] = portSequence.map((port, index) => {
          if (
            index === 0 &&
            formData.callbacks[0] &&
            formData.callbacks[0].port === portSequence[0].port
          ) {
            return {
              ...formData.callbacks[0],
              date: port.date,
            }
          }

          if (
            index === 0 &&
            formData.callbacks[0] &&
            formData.callbacks[0].port !== portSequence[0].port
          ) {
            return {
              date: port.date,
              port: port.port,
              hours: [
                {
                  openHour: '',
                  closeHour: '',
                  callbacksPerHour: 0,
                },
              ],
            }
          }

          if (
            index === portSequence.length - 1 &&
            formData.callbacks[formData.callbacks.length - 1].port ===
              portSequence[portSequence.length - 1].port
          ) {
            return {
              ...formData.callbacks[formData.callbacks.length - 1],
              date: port.date,
            }
          }

          if (
            index === portSequence.length - 1 &&
            formData.callbacks[formData.callbacks.length - 1].port !==
              portSequence[portSequence.length - 1].port
          ) {
            return {
              date: port.date,
              port: port.port,
              hours: [
                {
                  openHour: '',
                  closeHour: '',
                  callbacksPerHour: 0,
                },
              ],
            }
          }

          if (oldCallbacks[index]) {
            return {
              ...formData.callbacks[index],
              date: port.date,
            }
          }

          return {
            date: port.date,
            port: port.port,
            hours: [
              {
                openHour: '',
                closeHour: '',
                callbacksPerHour: 0,
              },
            ],
          }
        })
        setFormData((oldData) => ({ ...oldData!, callbacks }))
      }
    }

    setActiveStep((prevStep) => prevStep + 1)
  }

  const onSubmitOfficeHoursForm = (officeHoursFormData: IOfficeHoursFormData) => {
    const { officeHours } = officeHoursFormData
    setFormData((oldData) => ({ ...oldData, officeHours }))
    setActiveStep((prevStep) => prevStep + 1)
  }

  const onSubmitCallbackTimeLimitsForm = async (
    callbackTimeLimitsFormData: ICallbackTimeLimitsFormData,
  ) => {
    const { callbacks } = callbackTimeLimitsFormData
    setFormData((oldData) => ({ ...oldData, callbacks }))

    setActiveStep((prevStep) => prevStep + 1)
  }

  const onUploadGuestExtraDataForm = (values: IUploadFileForm) => {
    if (values && values.file) {
      const newFiles = [...formData.files]
      newFiles.push({
        file: [values.file[0]],
        name: values.name,
      })
      setFormData((oldData) => ({
        ...oldData!,
        files: newFiles,
      }))
    }
  }

  const onSubmitGuestExtraDataForm = () => {
    setActiveStep((prevStep) => prevStep + 1)
  }

  const removeFileInFormData = (name: string) => {
    const newFiles = [...formData.files]
    const selectedFileIndex = newFiles.findIndex((file) => file.name === name)
    if (selectedFileIndex !== -1) {
      newFiles[selectedFileIndex] = {
        ...newFiles[selectedFileIndex],
        deleted: true,
      }
    }

    setFormData((oldData) => ({
      ...oldData!,
      files: newFiles,
    }))
  }

  const onAddNewInitiative = (theInitiative: IInitiative) => {
    const newInitiatives = [...formData.initiatives]

    if (theInitiative.deleted) {
      const initiativeDeletedIndex = newInitiatives.findIndex(
        (item) => item.id === theInitiative.initiative_id,
      )
      if (initiativeDeletedIndex !== -1) {
        newInitiatives[initiativeDeletedIndex] = {
          id: theInitiative.initiative_id,
          type: theInitiative.initiative_type,
          productName: theInitiative.product,
          productId: theInitiative.product_id,
          shipId: Number(theInitiative.ship_id),
          shipName: theInitiative.ship_name,
          date: theInitiative.date,
          deleted: true,
        }
      }
    } else {
      newInitiatives.push({
        id: theInitiative.initiative_id || -1 * (Math.random() * (1000 - 1) + 1),
        type: theInitiative.initiative_type,
        productName: theInitiative.product,
        productId: theInitiative.product_id,
        shipId: Number(theInitiative.ship_id),
        shipName: theInitiative.ship_name,
        date: theInitiative.date,
      })
    }

    setFormData((oldData) => ({
      ...oldData!,
      initiatives: newInitiatives,
    }))
  }

  const onSubmitForm = async () => {
    const portSequence: IPortSequenceCruisePayload[] = formData.portSequence.map((port) => ({
      port_id: port.port.toString(),
      arrival_date: `${moment(port.date).format('YYYY-MM-DD')} ${moment(
        port.startTime,
        'HH:mm',
      ).format('HH:mm:ss')}`,
      departure_date: `${moment(port.date).format('YYYY-MM-DD')} ${moment(
        port.endTime,
        'HH:mm',
      ).format('HH:mm:ss')}`,
      cruise_port_id: port.cruisePortId,
    }))

    const officeHours: ICallBackTimeCruisePayload[] = []

    formData.officeHours.forEach((hour) => {
      hour.hours.forEach((internalHour) => {
        officeHours.push({
          date: moment(hour.date).format('YYYY-MM-DD'),
          open_hour: moment(internalHour.openHour, 'HH:mm').format('hh:mm A'),
          close_hour: moment(internalHour.closeHour, 'HH:mm').format('hh:mm A'),
        })
      })
    })

    const callbacksPerHour: ICallBackTimeCruisePayload[] = []
    formData.callbacks.forEach((callback) => {
      callback.hours.forEach((hour) => {
        callbacksPerHour.push({
          callbacksPerHour: hour.callbacksPerHour,
          close_hour: hour.closeHour,
          date: moment(callback.date).format('YYYY-MM-DD'),
          open_hour: hour.openHour,
        })
      })
    })

    const theInitiatives = formData.initiatives
      .filter((item) => !item.deleted || (item.deleted && item.id > 0))
      .map((item) => ({
        id: item.id && item.id < 0 ? undefined : item.id || undefined,
        type: item.type,
        productId: Number(item.productId),
        shipId: Number(item.shipId),
        date: moment(item.date).format('YYYY-MM-DD'),
        deleted: item.deleted || false,
      }))

    const newFormData = new FormData()
    const payload: ICreateCruisePayload = {
      ship_id: formData.vessel.toString(),
      destination_id: formData.product.toString(),
      description: formData.name,
      voyage_target: formData.target,
      voyage_number: formData.voyage_number,
      sailed_bookings: formData.sailed_bookings,
      penetration_goal: formData.penetration_goal,
      start_port: formData.embarkPort.toString(),
      end_port: formData.debarkPort.toString(),
      start_date: `${moment(formData.embarkDate).format('YYYY-MM-DD')} ${moment(
        formData.embarkTime,
        'HH:mm',
      ).format('HH:mm:ss')}`,
      end_date: `${moment(formData.debarkDate).format('YYYY-MM-DD')} ${moment(
        formData.debarkTime,
        'HH:mm',
      ).format('HH:mm:ss')}`,
      port_sequence: portSequence,
      office_hours: officeHours,
      callback_times: callbacksPerHour,
      cruise_id: parseInt(id!, 10),
      deletedFiles: formData.files.filter((item) => item.deleted && item.id),
      initiatives: theInitiatives,
    }
    const jsonData = JSON.stringify(payload)
    newFormData.append('data', jsonData)

    formData.files
      .filter((item) => item.file && !item.id && !item.deleted)
      .forEach((item) => {
        newFormData.append('files', new File([item.file![0]], item.name))
      })

    await create(newFormData, onSuccessEditCruise)
  }

  const goBack = () => {
    setActiveStep((prevStep) => prevStep - 1)
  }

  const editPort = (portId: number) => {
    const item = ports?.find((p) => p.port_id === portId)
    setPortToEdit(item)
    setIsOpenPortDialog(true)
  }

  const onPortSaved = () => {
    setIsLoadingPort(false)
    setIsOpenPortDialog(false)
  }

  const onAcceptPort = async (thePort: IPort): Promise<void> => {
    setIsLoadingPort(true)
    setSelectedTimezone(thePort.timezone)
    await createEdit(
      {
        port_id: thePort.port_id,
        name: thePort.name,
        timezone: thePort.timezone,
        timezoneOffset: moment().tz(thePort.timezone).utcOffset().toString(),
        isEdit: !!portToEdit,
      },
      onPortSaved,
      () => setIsLoadingPort(false),
    )
  }

  const steps = [
    {
      label: 'Enter basic data for voyage',
      content: (
        <BasicDataForm
          products={products ?? []}
          vessels={vessels ?? []}
          ports={ports ?? []}
          values={{
            debarkDate: formData.debarkDate,
            debarkPort: formData.debarkPort,
            debarkTime: formData.debarkTime,
            embarkDate: formData.embarkDate,
            embarkPort: formData.embarkPort,
            embarkTime: formData.embarkTime,
            penetration_goal: formData.penetration_goal,
            voyage_number: formData.voyage_number,
            sailed_bookings: formData.sailed_bookings,
            name: formData.name,
            product: formData.product,
            target: formData.target,
            vessel: formData.vessel,
          }}
          onSubmit={onSubmitBasicDataForm}
          hasAppointments={data?.hasAppointments}
        />
      ),
    },
    {
      label: 'Define itinerary',
      content: (
        <ItineraryForm
          ports={ports ?? []}
          itinerary={formData.portSequence}
          goBack={goBack}
          onSubmit={onSubmitItineraryForm}
          editPort={editPort}
        />
      ),
    },
    {
      label: 'Set daily office hours',
      content: (
        <OfficeHoursForm
          ports={ports ?? []}
          officeHours={formData.officeHours}
          onSubmit={onSubmitOfficeHoursForm}
          goBack={goBack}
        />
      ),
    },
    {
      label: 'Set the call backs - appointments',
      content: (
        <CallbackTimeLimitsForm
          ports={ports ?? []}
          callbacks={formData.callbacks}
          onSubmit={onSubmitCallbackTimeLimitsForm}
          setStateCallbacks={() => {}}
          goBack={goBack}
          isLoading={isLoading}
        />
      ),
    },
    {
      label: 'Upload guest extra data',
      content: (
        <GuestExtraData
          files={formData?.files ?? []}
          onRemoveFile={removeFileInFormData}
          onUploadFile={onUploadGuestExtraDataForm}
          onSubmit={onSubmitGuestExtraDataForm}
          goBack={goBack}
          isLoading={isLoading}
        />
      ),
    },
    {
      label: 'Set Marketing Initiatives',
      content: (
        <MarketingInitiativesForm
          products={products || []}
          vessels={vessels || []}
          initiatives={formData?.initiatives ?? []}
          onSubmit={onSubmitForm}
          onAddNewInitiative={onAddNewInitiative}
          goBack={goBack}
          isLoading={isLoading}
          embarkDate={formData.embarkDate}
          debarkDate={formData.debarkDate}
        />
      ),
    },
  ]

  return (
    <SettingsLayout>
      <Container maxWidth={false}>
        <Typography variant="h3" sx={{ marginBottom: 2 }}>
          Voyage Details
        </Typography>
        {isLoadingGetCruise ? (
          <GridSpinner />
        ) : (
          <>
            <Stepper activeStep={activeStep} orientation="vertical">
              {steps.map((step, index) => (
                <Step key={step.label}>
                  <StepLabel
                    optional={
                      index === steps.length - 1 ? (
                        <Typography variant="caption">Last step</Typography>
                      ) : null
                    }>
                    {step.label}
                  </StepLabel>
                  <StepContent>{step.content}</StepContent>
                </Step>
              ))}
            </Stepper>

            <PortForm
              key={`form-edit-port-itinerary-${portToEdit?.port_id || isOpenPortDialog}`}
              port={portToEdit}
              isOpen={isOpenPortDialog}
              isLoading={isLoadingPort}
              onAccept={onAcceptPort}
              onReject={() => setIsOpenPortDialog(false)}
            />
          </>
        )}
      </Container>
    </SettingsLayout>
  )
}

export default EditVoyage
