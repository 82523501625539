import moment from 'moment'
import numeral from 'numeral'
import { Paper, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import { IPortStatsByAgent, IPortStatsByAgentTableProps } from './types'
import {
  headTableStyle,
  headTableBlueStyle,
  headTableDarkGreenStyle,
  headTableGreenStyle,
  headTableTangerineStyle,
  cellBorderRight,
} from '../../../styles/generic-styles'
import { parseDateToString } from '../../../utils/date'

const PortStatsByAgentTable: React.FC<IPortStatsByAgentTableProps> = ({ stats }) => {
  if (stats.length <= 0) return null

  return (
    <Paper>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell rowSpan={2} />
            <TableCell rowSpan={2} />
            <TableCell rowSpan={2} sx={{ ...headTableStyle, ...cellBorderRight }} align="center">
              Total Qualified Appts
            </TableCell>
            <TableCell rowSpan={2} sx={{ ...headTableStyle, ...cellBorderRight }} align="center">
              Conversion
            </TableCell>
            <TableCell
              rowSpan={2}
              sx={{ ...headTableBlueStyle, ...cellBorderRight }}
              align="center">
              Total Overall Appts
            </TableCell>
            <TableCell
              colSpan={2}
              sx={{ ...headTableGreenStyle, ...cellBorderRight }}
              align="center">
              Office Bookings
            </TableCell>
            <TableCell
              colSpan={2}
              sx={{ ...headTableGreenStyle, ...cellBorderRight }}
              align="center">
              Remote Bookings
            </TableCell>
            <TableCell
              rowSpan={2}
              sx={{ ...headTableGreenStyle, ...cellBorderRight }}
              align="center">
              Self Service Bookings
            </TableCell>
            <TableCell
              rowSpan={2}
              sx={{ ...headTableDarkGreenStyle, ...cellBorderRight }}
              align="center">
              Total
            </TableCell>
            <TableCell
              rowSpan={2}
              sx={{ ...headTableTangerineStyle, ...cellBorderRight }}
              align="center">
              Supplemental Bookings
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="center" sx={headTableGreenStyle}>
              BOOK NOW
            </TableCell>
            <TableCell align="center" sx={{ ...headTableGreenStyle, ...cellBorderRight }}>
              BOOK LATER
            </TableCell>
            <TableCell align="center" sx={headTableGreenStyle}>
              BOOK NOW
            </TableCell>
            <TableCell align="center" sx={{ ...headTableGreenStyle, ...cellBorderRight }}>
              BOOK LATER
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {stats.map((stat: IPortStatsByAgent, index) => {
            const totalStandardBookings =
              (stat.bookingOfficeNow || 0) +
              (stat.bookingOfficeLater || 0) +
              (stat.bookingRemoteNow || 0) +
              (stat.bookingRemoteLater || 0) +
              (stat.bookingSelfService || 0)

            return (
              <TableRow key={`row-${index}`}>
                <TableCell sx={{ ...cellBorderRight }}>
                  {moment.utc(parseDateToString(stat.date)).format('MM/DD/YYYY')}
                </TableCell>
                <TableCell sx={{ ...cellBorderRight }}>{stat.port}</TableCell>
                <TableCell align="center" sx={{ ...cellBorderRight }}>
                  {stat.qualifiedAppointments || 'None'}
                </TableCell>
                <TableCell align="center" sx={{ ...cellBorderRight }}>
                  {numeral(stat.conversion).format('0,0.00%')}
                </TableCell>
                <TableCell align="center" sx={{ ...cellBorderRight }}>
                  {stat.totalOverall || 'None'}
                </TableCell>
                <TableCell align="center" sx={{ ...cellBorderRight }}>
                  {stat.bookingOfficeNow || 'None'}
                </TableCell>
                <TableCell align="center" sx={{ ...cellBorderRight }}>
                  {stat.bookingOfficeLater || 'None'}
                </TableCell>
                <TableCell align="center" sx={{ ...cellBorderRight }}>
                  {stat.bookingRemoteNow || 'None'}
                </TableCell>
                <TableCell align="center" sx={{ ...cellBorderRight }}>
                  {stat.bookingRemoteLater || 'None'}
                </TableCell>
                <TableCell align="center" sx={{ ...cellBorderRight }}>
                  {stat.bookingSelfService || 'None'}
                </TableCell>
                <TableCell align="center" sx={{ ...cellBorderRight }}>
                  {totalStandardBookings || 0}
                </TableCell>
                <TableCell align="center" sx={{ ...cellBorderRight }}>
                  {stat.bookingSupplemental || 'None'}
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </Paper>
  )
}

export default PortStatsByAgentTable
